.siniestro-button {
  background-color: white !important;
  color: #2c7dfd !important;
  border: 1px solid #2c7dfd !important;
  font-size: 16px !important;
  font-weight: normal !important;
  padding: 12px 20px !important;
  transition: all 0.3s ease !important;
}

.siniestro-button:hover {
  background-color: #f8f9fa !important;
  transform: translateY(-1px);
  color: #2c7dfd !important;
}
