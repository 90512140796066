:root {
	--se-site-header-height-mobile: 65.8px;
	/* AJUSTAR ESTE VALOR CON EL ALTO DEL HEADER */
	--se-section-header-height-mobile: 90px;
	/* ALTO DE SECCION AZUL */
}


.px-20,
.ps-20 {
	padding-left: 20px;
}

.px-20,
.pe-20 {
	padding-right: 20px;
}

.px-40,
.pe-40 {
	padding-right: 40px;
}

.py-13,
.pt-13 {
	padding-top: 13px;
}

.py-18,
.pt-18 {
	padding-top: 18px;
}

.py-35,
.pt-35 {
	padding-top: 35px;
}

.py-13,
.pb-13 {
	padding-bottom: 13px;
}

.py-25,
.pb-25 {
	padding-bottom: 25px;
}

.py-30,
.pb-30 {
	padding-bottom: 30px;
}

.py-40,
.pb-40 {
	padding-bottom: 40px;
}

@media (min-width: 992px) {

	.px-lg-70,
	.ps-lg-70 {
		padding-left: 70px !important;
	}

	.px-lg-70,
	.pe-lg-70 {
		padding-right: 70px !important;
	}

	.py-lg-13,
	.pt-lg-13 {
		padding-top: 13px !important;
	}

	.py-lg-35,
	.pt-lg-35 {
		padding-top: 35px !important;
	}

	.py-lg-60,
	.pt-lg-60 {
		padding-top: 60px !important;
	}

	.py-lg-80,
	.pt-lg-80 {
		padding-top: 80px !important;
	}

	.py-lg-13,
	.pb-lg-13 {
		padding-bottom: 13px !important;
	}

	.py-lg-25,
	.pb-lg-25 {
		padding-bottom: 25px !important;
	}

	.py-lg-30,
	.pb-lg-30 {
		padding-bottom: 30px !important;
	}

	.py-lg-60,
	.pb-lg-60 {
		padding-bottom: 60px !important;
	}

	.py-lg-80,
	.pb-lg-80 {
		padding-bottom: 80px !important;
	}
}


.my-18,
.mt-18 {
	margin-top: 18px;
}

.my-35,
.mt-35 {
	margin-top: 35px;
}

.my-40,
.mt-40 {
	margin-top: 40px;
}

.my-45,
.mt-45 {
	margin-top: 45px;
}

.my-16,
.mb-16 {
	margin-bottom: 16px;
}

.my-18,
.mb-18 {
	margin-bottom: 18px;
}

.my-25,
.mb-25 {
	margin-bottom: 25px;
}

.my-30,
.mb-30 {
	margin-bottom: 30px;
}

.my-40,
.mb-40 {
	margin-bottom: 40px;
}

.my-45,
.mb-45 {
	margin-bottom: 45px;
}

.mb-m13 {
	margin-bottom: -13px;
}

@media (min-width: 992px) {

	.my-lg-35,
	.mt-lg-35 {
		margin-top: 35px;
	}

	.my-lg-45,
	.mt-lg-45 {
		margin-top: 45px;
	}

	.my-lg-25,
	.mb-lg-25 {
		margin-bottom: 25px;
	}

	.my-lg-30,
	.mb-lg-30 {
		margin-bottom: 30px;
	}

	.my-lg-45,
	.mb-lg-45 {
		margin-bottom: 45px;
	}

	.mb-lg-m30 {
		margin-bottom: -30px;
	}
}




.maxw-60 {
	max-width: 60%;
}

.maxw-80 {
	max-width: 80%;
}

.maxw-90 {
	max-width: 90%;
}

.maxw-400p {
	max-width: 400px;
}

.maxw-1100p {
	max-width: 1100px;
}

.maxw-1170p {
	max-width: 1170px;
}

@media (min-width: 768px) {
	.maxw-md-475p {
		max-width: 475px;
	}
}

@media (min-width: 992px) {
	.maxw-lg-100 {
		max-width: 100%;
	}
}



.height-30 {
	height: 60px;
	width: 140px;
}

.minh-90p {
	min-height: 90px;
}

@media (min-width: 992px) {
	.maxw-lg-400p {
		max-width: 400px;
	}

	.minh-lg-125p {
		min-height: 125px;
	}
}




.fs-12 {
	font-size: 12px;
}

.fs-14 {
	font-size: 14px;
}

.fs-15 {
	font-size: 15px;
}

.fs-17 {
	font-size: 17px;
}

.fs-18 {
	font-size: 18px;
}

.fs-20 {
	font-size: 20px;
}

.fs-24 {
	font-size: 24px;
}

.fs-30 {
	font-size: 30px;
}

.fs-32 {
	font-size: 32px;
}

@media (min-width: 768px) {
	.fs-md-16 {
		font-size: 16px;
	}

	.fs-md-17 {
		font-size: 17px;
	}

	.fs-md-24 {
		font-size: 24px;
	}

	.fs-md-32 {
		font-size: 32px;
	}
}

@media (min-width: 992px) {
	.fs-lg-15 {
		font-size: 15px;
	}

	.fs-lg-18 {
		font-size: 18px;
	}

	.fs-lg-20 {
		font-size: 20px;
	}

	.fs-lg-24 {
		font-size: 24px;
	}

	.fs-lg-32 {
		font-size: 32px;
	}

	.fs-lg-40 {
		font-size: 40px;
	}
}

.lh-130 {
	line-height: 1.3;
}



.text-gray-dark {
	color: var(--se-gray-dark) !important;
}

.text-gray-aux {
	color: var(--se-gray-aux) !important;
}

.text-7a7a7a {
	color: #7a7a7a;
}

.bg-e6e6e6 {
	background-color: #e6e6e6;
}

.rounded-5p {
	border-radius: 5px;
}

.border-e0e0e0 {
	border-color: #E0E0E0 !important;
}

.hover-border-underline:hover {
	border-bottom: 1px solid;
}


@media (min-width: 992px) {
	.border-lg-0 {
		border-width: 0 !important;
	}
}



.btn1-md {
	padding: 8px 30px;
}

.btn1-dark {
	color: #fff;
	background-color: var(--se-gray-dark);
}




/**/
.form-wrapper {
	padding-top: 20px;
	padding-bottom: 30px;
}

@media (min-width: 768px) {
	.form-wrapper {
		background-color: #fff;
		border-radius: 23px;
		box-shadow: 0px 0px 8px 0px rgba(164, 164, 164, 0.25);
	}
}





/* */
.fixed-top-md {
	position: sticky;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.top-site-header {
	top: var(--se-site-header-height-mobile);
}

.top-section-header {
	top: calc(var(--se-site-header-height-mobile) + var(--se-section-header-height-mobile) - 13px);
}

@media (min-width: 768px) {
	.fixed-top-md {
		position: static;
	}
}




/**/
.mock1-img {
	height: 360px;
	margin-bottom: -3rem;
}

/*430*/

@media (min-width: 768px) {
	.mock2-img {
		position: absolute;
		max-height: 140%;
		right: -170px;
		bottom: -80px;
	}
}

@media (min-width: 992px) {
	.mock2-img {
		max-height: 120%;
		right: -170px;
		bottom: -50px;
	}
}


.badge-store-banner {
	height: 40px;
}

@media (min-width: 992px) {
	.badge-store-banner {
		height: 50px;
	}
}