.terceros-container {
    padding: 6px;
    max-width: 800px;
    margin: 0 auto;
}

.terceros-title {
    font-size: 16px;
    margin-bottom: 8px;
    color: #333;
}

.terceros-list {
    margin-bottom: 16px;
}

@media (min-width: 778px) {
    .terceros-list {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 12px;
    }
}

.tercero-card {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.tercero-info p {
    margin: 5px 0;
    font-size: 14px;
}

.tercero-actions {
    display: flex;
}

.action-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
}

.action-button.edit {
    color: #2c7dfd;
}

.action-button.delete {
    color: #dc3545;
}

.tercero-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.form-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-end;
}

.add-tercero-button {
    background-color: white !important;
    color: #2c7dfd !important;
    border: 1px solid #2c7dfd !important;
    font-size: 14px !important;
    font-weight: normal !important;
    padding: 8px 16px !important;
}

.save-button {
    background-color: #2c7dfd !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: normal !important;
}

.cancel-button {
    background-color: #e45555 !important;
    color: #ffffff !important;
    border: 1px solid #6c757d !important;
    font-size: 14px !important;
    font-weight: normal !important;
}
.cancel-button:hover{
    background: #d44545 !important;
}
.cancel-button:active{
    background: #e45555 !important;
}
.cancel-button:focus{
    background: #e45555 !important;
}
.cancel-button:focus:hover{
    background: #e45555 !important;
}
.personas-lesionadas-container {
    padding: 6px;
    max-width: 800px;
    margin: 0 auto;
}

.personas-lesionadas-title {
    font-size: 16px;
    margin-bottom: 8px;
    color: #333;
}

.personas-lesionadas-list {
    margin-bottom: 16px;
}

@media (min-width: 778px) {
    .personas-lesionadas-list {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 12px;
    }
}
.persona-card {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.persona-info p {
    margin: 5px 0;
    font-size: 14px;
}

.add-persona-button {
    background-color: white !important;
    color: #2c7dfd !important;
    border: 1px solid #2c7dfd !important;
    font-size: 14px !important;
    font-weight: normal !important;
    padding: 8px 16px !important;
}

.persona-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}
