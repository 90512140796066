/* botones */
.btn1 {
	display: inline-block;
	padding: 5px 16px;
	font-size: 13px;
	font-weight: 700;
	line-height: 1;
	color: #000;
	background-color: #fff;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	border: 0;
	border-radius: 5px;
}

.btn1-primary {
	color: #fff;
	background-color: var(--bs-primary);
}

.btn1-light {
	color: var(--se-gray-dark);
	background-color: #fff;
}




/* Contenedor princinpal sección precios */


.sec-precios .main-wrapper {
	max-width: 1230px;
	margin: 0 auto;
}

@media (min-width: 992px) {
	.sec-precios {
		padding: 0 60px;
		background-color: var(--se-gray-light);
	}
}



/* activo cotizado - desktop */
.ac-desktop {
	display: none;
}

@media (min-width: 992px) {
	.ac-desktop {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		margin-bottom: 8px;
		padding: 9px 20px;
		background-color: var(--se-gray-dark);
		color: #fff;
		font-size: 15px;
		line-height: 1.25;
		border-radius: 5px;
	}
}

/* activo cotizado - mobile */
.ac-mobile {
	position: relative;
	padding-top: 5px;
	z-index: 1021;
}



.ac-mobile .trigger,
.ac-mobile .detail {
	position: absolute;
	top: -10px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
	background-color: var(--se-gray-dark);
	font-size: 13px;
	color: #fff;
	border-radius: 5px;
}

.ac-mobile .trigger {
	display: inline-block;
	padding: 0 15px;
	line-height: 30px;
	z-index: 1;
}

.ac-mobile .detail {
	width: 310px;
	padding: 30px 22px 18px;
}

.ac-mobile .detail p {
	margin: 0;
	padding-top: 10px;
	border-top: 2px solid #bbb;
}

.ac-mobile .detail p b {
	max-width: 266px;
	text-wrap: balance;
}

.ac-mobile .trigger .icon {
	display: inline-block;
	fill: currentcolor;
	margin: 0 10px;
	transition: transform .25s linear;
}

.ac-mobile .trigger .ico-car {
	width: 33px;
	height: 16px;
}

.ac-mobile .trigger .ico-arrow {
	width: 11px;
	height: 11px;
	transform: rotate(90deg);
}

.ac-mobile .trigger:not(.collapsed) .ico-arrow {
	transform: rotate(-90deg);
}

/* ********************************************************* */


.ac-mobile-mob {
	position: relative;
	padding-top: 5px;
	z-index: 1021;
	font-weight: bold;
	font-size: 16px;
}

.ac-mobile-mob .trigger,
.ac-mobile-mob .detail {
	position: absolute;
	top: -10px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
	background-color: #FFFFFF;
	font-size: 16px;
	color: #2C7DFD;
	border-radius: 5px;
}

.ac-mobile-mob .trigger {
	display: inline-block;
	padding: 0 15px;
	line-height: 30px;
	z-index: 1;
}

.ac-mobile-mob .detail {
	width: inherit;
	padding: 30px 22px 18px;
	color: white;
	background-color: var(--se-gray-dark);
}

.ac-mobile-mob .detail p {
	margin: 0;
	padding-top: 10px;
	border-top: 2px solid #bbb;
	color: white;
}

.ac-mobile-mob .detail p b {
	max-width: 266px;
	text-wrap: balance;
	color: white;
}

.ac-mobile-mob .trigger .icon {
	display: inline-block;
	fill: currentcolor;
	margin: 0 10px;
	transition: transform .25s linear;
}

.ac-mobile-mob .trigger .ico-car {
	width: 33px;
	height: 16px;
}

.ac-mobile-mob .trigger .ico-arrow {
	width: 11px;
	height: 11px;
	/* transform: rotate(90deg); */
}

.ac-mobile-mob .trigger:not(.collapsed) .ico-arrow {
	transform: rotate(-90deg);
}

.ac-mobile-mob .trigger:not(.collapsed) .ico-car {
	color: white;
}

.ac-mobile-mob .trigger:not(.collapsed) {
	background-color: var(--se-gray-dark);
	color: white;
}





/* ********************************************************* */

/* banner azul */
.ban {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px 8px;
	line-height: 1.4;
	background-color: var(--bs-primary);
	font-size: 12px;
	color: #fff;
}

.ban>div:first-child {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.ban>div:first-child .icon {
	display: inline-block;
	width: 33px;
	height: 33px;
	margin-right: 6px;
}

@media (min-width: 992px) {
	.ban {
		border-radius: 5px;
		justify-content: space-between;
		padding: 5px 20px;
		margin-bottom: 8px;
		font-size: 14px;
		align-items: center;
	}

	.ban>div:first-child .icon {
		width: 26px;
		height: 26px;
		margin-right: 13px;
	}
}



/* tabs - tipos de cobertura */
.cob {
	margin-bottom: 8px;
}

.cob.sticky-top {
	margin-bottom: 4px;
	top: var(--se-header-height);
	background-color: var(--se-gray-light);
}

.cob.sticky-top::after {
	content: "";
	display: block;
	height: 12px;
}

.cob .nav {
	--bs-nav-link-padding-x: 24px;
	--bs-nav-link-padding-y: 8px;
	--bs-nav-link-color: #000;
	--bs-nav-link-hover-color: #000;
	--bs-nav-link-font-size: 14px;
	--bs-nav-link-font-weight: 700;
}

.cob .nav-tabs {
	--bs-nav-tabs-border-radius: 5px;
	--bs-nav-tabs-border-color: #fff;
	--bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) #fff;
	--bs-nav-tabs-link-active-border-color: #fff;
	background: #e9ecef;
	border-radius: 5px 5px 0 0;
}

.cob .tab-content {
	padding: 8px 24px;
	background: #fff;
	border-radius: 0 0 5px 5px;
	font-size: 12px;
}

.cob .tab-content .list-inline-item:not(:last-child) {
	margin-right: 14px;
}

.cob .tab-content .tick-pri {
	width: 13px;
	height: 9px;
	fill: var(--bs-primary);
}



/* tarjetas verticales - desktop only */
.cards1-wrapper {
	padding-bottom: 8px;
}

/* columna 1 - encabezados y cont slider */
.cards-header {
	width: 145px;
}

.cards-slider {
	max-width: 1085px;
}

.cards-header {
	margin: 8px 0;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
}

.cards-header>div {
	padding: 9px 0;
}

.cards-header .empresa {
	padding: 16px 0 9px;
}

.cards-header .cobertura {
	padding: 6px 0;
}

.cards-header .descuento {
	padding: 12px 0 13px;
}

.cards-header .precio {
	padding: 13px 0;
}

.cards-header .item {
	padding: 7px 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cards-header .item .bi {
	width: 15px;
	height: 15px;
	vertical-align: text-top;
}

/* slider */
.splide__pagination {
	display: none;
}

.splide__arrow--prev {
	left: -190px;
}

.splide__arrow--next {
	right: -45px;
}

.splide__arrow {
	background: var(--bs-primary);
}

.splide__arrow>svg {
	fill: #fff;
}

.splide__arrow:disabled {
	background: var(--se-gray-dark);
}

/* .splide-card1 .splide__arrow:disabled {display:none;} */


/* card1 */
/* margin en card1 para sumar la sombra en el calculo del ancho de cada slide */
.card1 {
	margin: 8px 12px 8px 8px;
	padding: 5px 15px 12px;
	width: 190px;
	text-align: center;
	font-weight: 500;
	font-size: 12px;
	background: #FFFEFC;
	box-shadow: 0px 0px 8px rgba(164, 164, 164, 0.3);
	border-radius: 5px;
	color: #363636;
}

.card1>div:not(:first-child) {
	padding: 9px 0;
	border-top: 1px dotted #D9D8D6;
}

.card1 .empresa {
	padding: 8px 0;
}

.card1 .cobertura {
	padding: 6px 0 !important;
	font-size: 12px;
	font-weight: 700;
}

.card1 .descuento {
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 15px;
	font-weight: 700;
	line-height: 1;
}

.card1 .descuento .antes {
	color: var(--se-gray-dark);
	padding: 0 5px;
	position: relative;
}

.card1 .descuento .antes:before {
	content: "";
	position: absolute;
	top: 6px;
	left: 0;
	right: 0;
	height: 2px;
	background: #B8C2C9;
}

.card1 .descuento .ahora {
	display: inline-block;
	padding: 6px 8px 4px 7px;
	color: #fff;
	background: var(--bs-primary);
	border-radius: 5px;
}

.card1 .precio {
	font-weight: 700;
	line-height: 1;
}

.card1 .precio .monto {
	font-size: 26px;
	color: #000;
}

.card1 .precio .periodo {
	font-size: 15px;
	color: var(--se-gray-dark);
	font-weight: 400;
}

.card1 .item {
	padding: 7px 15px !important;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.card1 .btn1 {
	padding: 5px 26px;
}

.card1 .btn1 .icon {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 0 2px 12px;
}






/* tabla - desktop only */
.filter-table {
	padding-bottom: 36px;
}

.filter-table .sticky-lg-top {
	z-index: 1019;
	top: var(--se-header-height);
}



@media (min-width: 992px) {
	.sticky-lg-top2 {
		z-index: 1019;
		top: var(--se-header-height);
		position: sticky;
	}
}

.card3-layout {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	text-align: center;
}

.card3-layout .header {
	display: flex;
	min-width: 375px;
	width: 375px;
	align-items: center;
}

.card3-layout .items {
	display: flex;
	flex: 1 1 auto;
	justify-content: space-between;
	align-items: stretch;
}

.card3-layout .items .item {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.card3-layout .items .item .bi {
	width: 16px;
	height: 16px;
}

.card3-layout .actions {
	display: flex;
	min-width: 230px;
	width: 230px;
	justify-content: center;
	align-items: center
}

.ft-filters {
	padding: 17px 50px;
	background: var(--bs-primary);
	color: #fff;
	border-radius: 5px 5px 0 0;
}

.ft-filters label {
	font-weight: 700;
}

.ft-filters .form-select {
	border: 0;
}

.ft-header {
	background: var(--bs-primary);
	color: #fff;
	font-size: 13px;
}

.ft-header .card3-layout .items {
	padding-bottom: 5px;
	align-items: flex-end;
	line-height: 1.25;
}


.ft-rows .card3 {
	margin-top: 10px;
	border-radius: 5px;
	background: #FFF;
	box-shadow: 0px 0px 8px 0px rgba(164, 164, 164, 0.30);
}

.ft-rows .card3 .items .item {
	border-right: 1px solid #A5A5A5;
}

.ft-rows .card3 .items .item:first-child {
	border-left: 1px solid #A5A5A5;
}

.ft-rows .card3 .items .icon {
	display: inline-block;
	width: 35px;
	height: 35px;
}

.ft-rows .card3 .btn1 {
	padding: 7px 11px 7px 30px;
	font-size: 15px;
}

.ft-rows .card3 .btn1 .icon {
	display: inline-block;
	width: 14px;
	height: 14px;
	margin: 0 0 2px 20px;
}


.ft-rows .card3 .header {
	padding: 10px 8px;
}


.ft-rows .card3 .cobertura {
	padding: 6px 0 2px;
	font-size: 12px;
}

.ft-rows .card3 .rating {
	font-size: 12px;
	color: var(--bs-primary);
}

.ft-rows .card3 .rating::before {
	content: "";
	display: block;
	width: 110px;
	margin: 0 auto 2px;
	border: 1px solid #ccc;
}

.ft-rows .card3 .cont {
	display: inline-block;
}

.ft-rows .card3 .descuento {
	display: flex;
	padding: 0 0 8px;
	justify-content: space-between;
	align-items: center;
	font-size: 15px;
	font-weight: 700;
	line-height: 1;
}

.ft-rows .card3 .descuento .antes {
	color: var(--se-gray-dark);
	padding: 0 5px;
	position: relative;
}

.ft-rows .card3 .descuento .antes:before {
	content: "";
	position: absolute;
	top: calc(50% - 1px);
	left: 0;
	right: 0;
	height: 2px;
	background: #a5a5a5;
}

.ft-rows .card3 .descuento .ahora {
	display: inline-block;
	padding: 5px 9px 4px 9px;
	color: #fff;
	background: var(--bs-primary);
	border-radius: 5px;
}

.ft-rows .card3 .precio {
	font-weight: 700;
	line-height: 1;
}

.ft-rows .card3 .precio .monto {
	font-size: 25px;
	color: #000;
}

.ft-rows .card3 .precio .periodo {
	font-size: 15px;
	color: var(--se-gray-dark);
	font-weight: 400;
}

.ft-rows .card3 .precio .nota {
	font-size: 16px;
	color: #000;
	line-height: 1.2;
}






/* tarjetas horizontales - mobile y desktop */
.cards2-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	/*max-width:1100px;*/
	margin: 0 auto;
	margin-top: 1rem;
	padding: 20px 20px 50px;
}

/* card2 */
.card2 {
	width: 100%;
	max-width: 350px;
	margin-bottom: 20px;
	text-align: center;
	font-weight: 500;
	font-size: 12px;
	color: #363636;
	background: #fff;
	box-shadow: 0px 0px 4px #DCE0E2;
	border-radius: 5px;
}

.card2 .header {
	background: #D9D9D9;
	border-radius: 5px 5px 0 0;
	font-size: 16px;
	color: #000;
	line-height: 26px;
}

.card2 .body {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 18px 0;
}

.card2 .empresa {
	padding: 0 0 8px;
}

.card2 .empresa img {
	max-width: 85%;
}

.card2 .cobertura {
	display: none;
}

.card2 .rating {
	font-size: 12px;
	color: var(--bs-primary);
}

.card2 .rating::before {
	content: "";
	display: block;
	width: 110px;
	margin: 0 auto 5px;
	border: 1px solid #ccc;
}

.card2 .features {
	position: relative;
	display: flex;
	width: 90%;
	margin: 0 auto;
	padding-top: 10px;
	justify-content: space-between;
	align-items: center;
	line-height: 1;
	color: var(--se-gray-dark);
}

.card2 .features::before {
	position: absolute;
	width: 100%;
	top: 0;
	content: "";
	border-top: 2px solid #ccc;
}

.card2 .features .icon {
	display: inline-block;
	fill: currentcolor;
	width: 17px;
	height: 17px;
}

.card2 .features .icon.on {
	color: var(--bs-primary);
}

.card2 .cont {
	display: inline-block;
	max-width: 95%;
	/*min-width:150px;*/
}

.card2 .descuento {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 8px;
	font-size: 13px;
	font-weight: 700;
	line-height: 1;
}

.card2 .descuento .antes {
	color: var(--se-gray-dark);
	padding: 0 5px;
	position: relative;
	font-size: 15px;
}

.card2 .descuento .antes:before {
	content: "";
	position: absolute;
	top: calc(50% - 1px);
	left: 0;
	right: 0;
	height: 2px;
	background: #a5a5a5;
}

.card2 .descuento .ahora {
	display: inline-block;
	padding: 4px 8px 4px 7px;
	color: #fff;
	background: var(--bs-primary);
	border-radius: 5px;
}

.card2 .precio {
	padding-bottom: 10px;
	font-weight: 700;
	line-height: 1;
}

.card2 .precio .monto {
	font-size: 30px;
	color: #000;
}

.card2 .precio .periodo {
	font-size: 15px;
	color: var(--se-gray-dark);
	font-weight: 400;
}

.card2 .btn1 {
	padding: 6px;
	width: 100%;
}

.card2 .btn1 .icon {
	display: inline-block;
	width: 15px;
	height: 15px;
	margin: 0 0 2px 12px;
}

@media (min-width: 768px) {
	.cards2-wrapper {
		justify-content: space-between;
	}
}

@media (min-width: 992px) {
	.cards2-wrapper {
		padding: 0 0 150px;
		background-color: transparent;
	}

	.card2 {
		max-width: 48%;
		box-shadow: 0px 0px 4px #DCE0E2;
	}

	.card2 .header {
		display: none;
	}

	.card2 .body {
		padding: 15px;
	}

	.card2 .empresa img {
		max-height: 40px;
	}

	.card2 .cobertura {
		display: block;
		padding: 6px 0 2px;
		font-size: 14px;
		font-weight: 400;
	}

	.card2 .rating {
		font-size: 15px;
	}

	.card2 .rating::before {
		width: 155px;
	}

	.card2 .features {
		justify-content: space-evenly;
	}

	.card2 .features.icons-lg-20 .icon {
		width: 20px;
		height: 20px;
	}

	.card2 .cont {
		min-width: 170px;
	}

	.card2 .descuento {
		padding-bottom: 18px;
	}

	.card2 .descuento .antes {
		padding: 0px 10px;
		font-size: 20px;
	}

	.card2 .descuento .ahora {
		padding: 5px 16px;
		font-size: 13px;
	}



	.card2 .precio .monto {
		font-size: 34px;
	}

	.card2 .btn1 {
		padding: 10px 26px;
		/*width:auto;*/
		font-size: 17px;
	}

}

@media (min-width: 1200px) {
	/* .card2 {max-width:540px;} */
}




/* off-canvas - coberturas */
.oc-cob {
	--bs-offcanvas-width: 900px;
	--bs-offcanvas-padding-x: 0;
	--bs-offcanvas-padding-y: 0;

}

.oc-cob1 {
	--bs-offcanvas-width: 540px;
	--bs-offcanvas-padding-x: 0;
	--bs-offcanvas-padding-y: 0;

}

.oc-cob .offcanvas-body {
	position: relative;
	display: flex;
	flex-direction: column;
}

.oc-cob .btn-close,
.modal-wpp .btn-close {
	--bs-btn-close-color: #343434;
	--bs-btn-close-bg: url("data:image/svg+xml,%3Csvg width='39' height='39' viewBox='-2 0 42 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.0145 24.4855L24.4855 14.0145M24.4855 24.4855L14.0145 14.0145M19.25 37.75C29.425 37.75 37.75 29.425 37.75 19.25C37.75 9.075 29.425 0.75 19.25 0.75C9.075 0.75 0.75 9.075 0.75 19.25C0.75 29.425 9.075 37.75 19.25 37.75Z' stroke='%230b5ed7' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E");
	--bs-btn-close-focus-shadow: none;
	padding: 0;
	border-radius: 0;
	font-size: 40px;
}

.oc-cob .btn-close {
	position: fixed;
	z-index: 10000;
	top: 1rem;
	right: 1rem;
}


/**/
.oc-cob .oc-sum {
	position: sticky;
	top: 0;
	z-index: 9999;
	background-color: #fff;
	padding: 20px 45px 20px;
	text-align: center;
}

/*1045: accordion*/

.oc-cob .oc-sum .cobertura {
	font-size: 19px;
	font-weight: 700;
	line-height: 1.1;
}

.oc-cob .oc-sum .empresa {
	margin: 15px 0;
}

.oc-cob .oc-sum .empresa img {
	max-width: 100%;
	max-height: 35px;
}

.oc-cob .oc-sum .descuento {
	display: none;
}

.oc-cob .oc-sum .precio {
	margin: 15px 0;
	font-weight: 700;
	line-height: 1;
}

.oc-cob .oc-sum .precio .monto {
	font-size: 33px;
	color: #000;
}

.oc-cob .oc-sum .precio .periodo {
	font-size: 15px;
	color: var(--se-gray-dark);
	font-weight: 400;
}



.oc-cob .oc-sum .cta .btn {
	width: 100%;
	font-size: 20px;
}

.oc-cob .cta-wa {
	margin-bottom: 50px;
	font-size: 17px;
	text-align: center;
}

.oc-cob .cta-wa p {
	margin-bottom: 0;
	color: var(--se-gray-dark);
	font-weight: 500;
}

.oc-cob .cta-wa a {
	color: var(--se-gray-aux);
	font-weight: 700;
}

.oc-cob .cta-wa a:hover span {
	border-bottom: 1px solid var(--se-gray-aux);
}

.oc-cob .cta-wa .icon {
	display: inline-block;
	width: 24px;
	height: 24px;
}

.oc-cob .datafiscal {
	display: flex;
	font-size: 10px;
	line-height: 1.1;
	text-align: left;
}

.oc-cob .datafiscal img {
	width: 30px;
	margin-right: 15px;
}

/*
.det-modalPrecios {
	height: 160%;
}

.det-modalPreciosChico {
	height: 160%;
}

.det-modalPreciosGrande {
	height: 160%;
}*/



.oc-cob .oc-det {
	padding: 1rem;
	z-index: 1046;
	background-color: #fff;
}

.oc-cob .oc-det .suma {
	margin-bottom: 30px;
	font-size: 17px;
	text-align: center;
}

.oc-cob .oc-det .suma span {
	display: inline-block;
	font-weight: 700;
	color: #F59B00;
	line-height: 1;
}

.oc-cob .oc-detspan .suma span {
	display: inline-block;
	font-weight: bold;
	font-size: 18px;
	color: #F59B00;
	line-height: 1;
}

.oc-cob1 .oc-detwidth {
	width: 100%;
	padding: 0px 50px 0px 30px;
}

.oc-cob .oc-det .icon {
	width: 16px;
	height: 16px;
	vertical-align: 0;
	fill: var(--bs-primary);
}


.accordionclass{
	font-family: 'DM Sans';
	font-size: small;
	font-weight: bold;
	padding-left: 0px;
	padding-right: 0px;
	border: "blue";
}
.accordionclass2{
	font-family: 'DM Sans';
	font-size: small;
	font-weight: 300;
	padding-left: 0px;
	padding-right: 0px;
	border: "blue";
}

.accordionitem{
	border-bottom: 1px solid #cfcfcf;
}

.oc-cob .oc-det .acc-01 {
	--bs-accordion-bg: transparent;
	--bs-accordion-border-color: #E3E3E3;
	--bs-accordion-btn-focus-box-shadow: none;
	--bs-accordion-active-color: var(--bs-primary);
	--bs-accordion-active-bg: transparent;
	margin-bottom: 20px;
}

.oc-cob .oc-det .acc-01 .accordion-header .accordion-button {
	padding: 12px 1.25rem 12px 0;
	font-size: 16px;
	font-weight: 500;
}

.oc-cob .oc-det .acc-01 .accordion-header .accordion-button:not(.collapsed) {
	box-shadow: none;
}

.oc-cob .oc-det .acc-01 .accordion-header .accordion-button .icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

.oc-cob .oc-det .acc-01 .accordion-body {
	padding: 0 50px 15px 0;
	font-size: 14px;
	line-height: 1.25;
}

.oc-cob .oc-det .acc-01 .accordion-body p {
	margin-bottom: 10px;
}

.oc-cob .oc-det .acc-01 .accordion-body p:last-child {
	margin-bottom: 0;
}

@media (min-width: 992px) {
	.oc-cob .btn-close {
		top: 20px;
		right: 25px;
		font-size: 40px;
	}

	.oc-cob .offcanvas-body {
		flex-direction: row;
		scrollbar-gutter: stable;
		/*flex-wrap:wrap; prevents sticky*/
	}

	.oc-cob1 .offcanvas-body {
		flex-direction: column;
		scrollbar-gutter: stable;
		/*flex-wrap:wrap; prevents sticky*/
	}

	.oc-cob .oc-sum {
		padding: 36px 36px 50px;
		width: 40%;
	}

	.oc-cob .oc-sum .cobertura {
		font-size: 26px;
	}

	.oc-cob .oc-sum .empresa {
		margin: 50px 0;
	}

	.oc-cob .oc-sum .empresa img {
		max-height: 80px;
	}

	.oc-cob .oc-sum .descuento {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 15px;
		font-weight: 700;
		line-height: 1;
	}

	.oc-cob .oc-sum .descuento .antes {
		color: var(--se-gray-dark);
		margin-right: 15px;
		padding: 0 5px;
		position: relative;
	}

	.oc-cob .oc-sum .descuento .antes:before {
		content: "";
		position: absolute;
		top: 6px;
		left: 0;
		right: 0;
		height: 2px;
		background: #B8C2C9;
	}

	.oc-cob .oc-sum .descuento .ahora {
		display: inline-block;
		padding: 6px 8px 4px 7px;
		color: #fff;
		background: var(--bs-primary);
		border-radius: 5px;
	}

	.oc-cob .oc-sum .precio {
		margin: 20px 0 30px;
	}



	.oc-cob .oc-sum .cta {
		margin-bottom: 50px;
	}

	.oc-cob .oc-sum .cta .btn {
		width: 90%;
	}

	.oc-cob .oc-sum .cta-wa {
		margin-bottom: 60px;
		font-size: 16px;
		line-height: 1.25;
	}

	/**/
	.oc-cob .oc-det {
		padding: 30px 45px 50px 50px;
		width: 60%;
	}

	.oc-cob1 .oc-det1 {
		width: 100%;
		padding: 30px 45px 50px 50px;

	}


	.oc-cob .oc-det .suma {
		margin-bottom: 30px;
		font-size: 20px;
		text-align: left;
	}

	.oc-cob .oc-det .suma span {
		font-size: 22px;
		font-weight: 500;
	}

	.oc-cob .oc-det .icon {
		width: 18px;
		height: 18px;
	}



	.oc-cob .oc-det .acc-01 .accordion-body {
		padding: 0 60px 15px 0;
	}

}


@media (min-width: 992px) {
	.opt2 .card2 {
		max-width: 32%;
	}
}




/* Seccion solicitud recibida */
.sec-solicitud {
	padding: 35px 20px 120px;
	text-align: center;
	background-color: var(--bs-primary);
	color: #fff;
}

.sec-solicitud .intro .icon {
	height: 50px;
}

.sec-solicitud .intro .title {
	margin: 25px 0 15px;
	font-size: 26px;
	font-weight: 700;
}

.sec-solicitud .intro .copy {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.2;
}

.sec-solicitud .info {
	margin: 30px 0;
	padding: 30px 10px 20px;
	background-color: #fff;
	color: #000;
	text-align: left;
	border-radius: 5px;
}

.sec-solicitud .info .info-header {
	margin: 0 0px 30px;
	padding: 0 0 30px;
	text-align: center;
	border-bottom: 1px solid var(--bs-primary);
}

.sec-solicitud .info .info-header .cobertura {
	margin-bottom: 10px;
	font-size: 24px;
	font-weight: 700;
	line-height: 1.2;
}

.sec-solicitud .info .info-header .aseguradora {
	max-height: 40px;
}



.sec-solicitud .info .dl01 .icon {
	width: 24px;
	height: 24px;
	margin-right: 7px;
	vertical-align: top;
	fill: var(--bs-primary);
}

.sec-solicitud .info .dl01 dt {
	font-weight: normal;
}

.sec-solicitud .info .dl01 dd {
	margin: 0 0 18px;
	padding-left: 35px;
	font-weight: 700;
	line-height: 1.2;
}

.sec-solicitud .info .info-footer {
	margin-top: 40px;
	font-size: 17px;
	font-weight: 500;
	text-align: center;
}

.sec-solicitud .info .info-footer .icon {
	width: 24px;
	height: 24px;
}

.sec-solicitud .info .info-footer a {
	color: #000;
}

.sec-solicitud .info .info-footer a:hover span {
	border-bottom: 1px solid var(--se-gray-aux);
}


@media (min-width: 768px) {
	.sec-solicitud {
		padding: 30px 20px 50px;
	}

	.sec-solicitud .intro .icon {
		height: 45px;
	}

	.sec-solicitud .intro .title {
		margin: 18px 0 15px;
		font-size: 30px;
	}

	.sec-solicitud .intro .copy {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		font-size: 18px;
	}

	.sec-solicitud .info {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		max-width: 800px;
		margin: 30px auto;
		padding: 30px 30px 20px;
	}

	.sec-solicitud .info .info-header {
		width: 45%;
		margin: 0;
		padding: 0 30px 0 0;
		border-bottom: 0;
	}

	.sec-solicitud .info .info-body {
		width: 100%;
	}

	.sec-solicitud .info .info-header+.info-body {
		width: 55%;
	}

	.sec-solicitud .info .info-footer {
		margin-top: 30px;
		width: 100%;
	}

	.skeleton-container {
		align-self: center;
	}
}

.grecaptcha-badge {
	width: 70px !important;
	overflow: hidden !important;
	transition: all 0.3s ease !important;
	left: -2px !important;
	bottom: 20px !important;
}

.grecaptcha-badge:hover {
	width: 256px !important;
}

.d-flex {
	display: -webkit-box !important;
	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -ms-flexbox !important;
	/* TWEENER - IE 10 */
	display: -webkit-flex !important;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: flex !important;
	/* NEW, Spec - Firefox, Chrome, Opera */
}

@media (max-width: 360px) {
	.form-check-inline {
		margin-right: 0.1rem;
	}

	.form-switch.form-check-reverse .form-check-input {
		margin-right: -2.2em;
	}
}