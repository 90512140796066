@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
* {
    -webkit-tap-highlight-color: transparent;
}

*:focus {
    outline: none;
}

.form-control:focus,
.form-select:focus,
.form-check-input:focus {
    box-shadow: none;
}

.form-control::placeholder {
    color: #A5A5A5;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background-color: #a5a5a5;
    border-color: #a5a5a5;
}


/* root variables BS */
:root {
    /* --bs-primary: #2C7DFD; */
    /* --bs-primary-rgb: 44, 125, 253; */
    --bs-primary: #3F84FF;
    --bs-primary-rgb: 63, 132, 255;

    --bs-font-sans-serif: "DM Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    --bs-body-color: #363636;
    --bs-body-color-rgb: 54, 54, 54;

    --bs-link-color: #3F84FF;
    --bs-link-color-rgb: 63, 132, 255;
    --bs-link-decoration: underline;
    --bs-link-hover-color: #2C7DFD;
    --bs-link-hover-color-rgb: 44, 125, 253;
}

/* root variables SE */
:root {
    --se-primary: #2C7DFD;
    --se-gray-dark: #A5A5A5;
    --se-oneteam-color: #893280;
}


/* Redefino */
a {
    text-decoration: none;
}

.btn {
    --bs-btn-padding-y: 4px;
    --bs-btn-padding-x: 18px;
}

.btn-primary {
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-disabled-bg: var(--bs-primary);
    --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-secondary {
    --bs-btn-bg: #939393;
    --bs-btn-border-color: #939393;
    --bs-btn-disabled-bg: #939393;
    --bs-btn-disabled-border-color: #939393;
}

.modal {
    --bs-modal-border-radius: 20px;
    --bs-modal-header-padding: 12px 16px 0 16px
        /*1rem 1rem*/
    ;
}

.modal-content {
    margin-bottom: 0.5rem;
}

.modal-dialog-end {
    display: flex;
    align-items: end;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
}

@media (min-width: 576px) {
    .modal-xl {
        --bs-modal-width: 90%;
    }
}


/* globales */
.w-12em {
    width: 1.2em;
    height: 1.2em;
}

.w-40p {
    width: 40px;
}

.w-64p {
    width: 64px;
}

.w-43p {
    width: 43px;
}

.w-90p {
    width: 90px;
}

.w-128p {
    width: 128px;
}

.w-200p {
    width: 200px;
}

.w-250p {
    width: 250px;
}

.w-180p {
    width: 180px;
}

.w-195p {
    width: 195px;
}

.w-380p {
    width: 380px;
}

.mw-auto {
    min-width: auto;
}

.mw-190 {
    min-width: 190px;
}

.maxw-90 {
    max-width: 90%;
}

.maxw-330p {
    max-width: 330px;
}

.maxw-370p {
    max-width: 370px;
}

.maxw-400p {
    max-width: 400px;
}

.maxw-460p {
    max-width: 460px;
}

.maxw-500p {
    max-width: 500px;
}

.maxw-560p {
    max-width: 560px;
}

.maxw-660p {
    max-width: 660px;
}

.maxw-730p {
    max-width: 730px;
}

.maxw-760p {
    max-width: 760px;
}

.h-64p {
    height: 64px;
}

.h-90p {
    height: 90px;
}


@media (min-width: 576px) {
    .mw-sm-180 {
        min-width: 180px;
    }
}

@media (min-width: 992px) {
    .w-lg-auto {
        width: auto;
    }

    .w-lg-70p {
        width: 70px;
    }

    .w-lg-90p {
        width: 90px;
    }

    .w-lg-105p {
        width: 105px;
    }

    .w-lg-220p {
        width: 220px;
    }

    .w-lg-235p {
        width: 235px;
    }

    .w-lg-250p {
        width: 250px;
    }

    .w-lg-260p {
        width: 260px;
    }

    .w-lg-360p {
        width: 360px;
    }

    .maxw-lg-920p {
        max-width: 920px;
    }

    .maxw-lg-80 {
        max-width: 80% !important;
    }

    .maxw-lg-100 {
        max-width: 100% !important;
    }

    .h-lg-105p {
        height: 105px;
    }

    .minh-lg-80p {
        min-height: 80px;
    }
}




.row-gap-45 {
    row-gap: 2rem !important;
}

.row-gap-30p {
    row-gap: 30px !important;
}

.row-gap-35p {
    row-gap: 35px !important;
}


@media (min-width: 992px) {
    .row-gap-lg-30p {
        row-gap: 30px !important;
    }

    .row-gap-lg-35p {
        row-gap: 35px !important;
    }

    .column-gap-lg-40p {
        column-gap: 40px !important;
    }
}


.mt-n50 {
    margin-top: -50px;
}

.mb-n5 {
    margin-bottom: -5px;
}


.mb-10 {
    margin-bottom: 10px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-18 {
    margin-bottom: 18px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-22 {
    margin-bottom: 22px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.me-10 {
    margin-right: 10px !important;
}

.me-12 {
    margin-right: 12px !important;
}

.me-20 {
    margin-right: 20px !important;
}

.me-30 {
    margin-right: 30px !important;
}

.pt-10,
.py-10 {
    padding-top: 10px;
}

.pb-10,
.py-10 {
    padding-bottom: 10px;
}

.pt-12,
.py-12 {
    padding-top: 12px;
}

.pb-12,
.py-12 {
    padding-bottom: 12px;
}

.pt-25,
.py-25 {
    padding-top: 25px;
}

.pb-25,
.py-25 {
    padding-bottom: 25px;
}

.pt-30,
.py-30 {
    padding-top: 30px;
}

.pb-30,
.py-30 {
    padding-bottom: 30px;
}

.pt-35,
.py-35 {
    padding-top: 35px;
}

.pb-35,
.py-35 {
    padding-bottom: 35px;
}

.pt-40,
.py-40 {
    padding-top: 40px;
}

.pb-40,
.py-40 {
    padding-bottom: 40px;
}

.pt-45,
.py-45 {
    padding-top: 45px;
}

.pb-45,
.py-45 {
    padding-bottom: 45px;
}

.pt-50,
.py-50 {
    padding-top: 50px;
}

.pb-50,
.py-50 {
    padding-bottom: 50px;
}

.pt-60,
.py-60 {
    padding-top: 60px;
}

.pb-60,
.py-60 {
    padding-bottom: 60px;
}

.pt-65,
.py-65 {
    padding-top: 65px;
}

.pb-65,
.py-65 {
    padding-bottom: 65px;
}

.pt-70,
.py-70 {
    padding-top: 70px;
}

.pb-70,
.py-70 {
    padding-bottom: 70px;
}

.pt-80,
.py-80 {
    padding-top: 80px;
}

.pb-80,
.py-80 {
    padding-bottom: 80px;
}

.pt-90,
.py-90 {
    padding-top: 90px;
}

.pb-90,
.py-90 {
    padding-bottom: 90px;
}

.pb-240 {
    padding-bottom: 240px;
}


.ps-10,
.px-10 {
    padding-left: 10px;
}

.pe-10,
.px-10 {
    padding-right: 10px;
}

.ps-12,
.px-12 {
    padding-left: 12px;
}

.pe-12,
.px-12 {
    padding-right: 12px;
}

.ps-16,
.px-16 {
    padding-left: 16px;
}

.pe-16,
.px-16 {
    padding-right: 16px;
}

.ps-18,
.px-18 {
    padding-left: 18px;
}

.pe-18,
.px-18 {
    padding-right: 18px;
}

.ps-20,
.px-20 {
    padding-left: 20px;
}

.pe-20,
.px-20 {
    padding-right: 20px;
}

.ps-25,
.px-25 {
    padding-left: 25px;
}

.pe-25,
.px-25 {
    padding-right: 25px;
}

.ps-30,
.px-30 {
    padding-left: 30px;
}

.pe-30,
.px-30 {
    padding-right: 30px;
}

.ps-35,
.px-35 {
    padding-left: 35px;
}

.pe-35,
.px-35 {
    padding-right: 35px;
}

.ps-40,
.px-40 {
    padding-left: 40px;
}

.pe-40,
.px-40 {
    padding-right: 40px;
}

.ps-45,
.px-45 {
    padding-left: 45px;
}

.pe-45,
.px-45 {
    padding-right: 45px;
}


@media (min-width: 360px) {
    .px-ss-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
}

@media (min-width: 992px) {
    .mt-lg-n60 {
        margin-top: -60px;
    }

    .mt-lg-70 {
        margin-top: 70px;
    }

    .mb-lg-10 {
        margin-bottom: 10px;
    }

    .mb-lg-12 {
        margin-bottom: 12px;
    }

    .mb-lg-20 {
        margin-bottom: 20px;
    }

    .mb-lg-25 {
        margin-bottom: 25px;
    }

    .mb-lg-30 {
        margin-bottom: 30px;
    }

    .mb-lg-32 {
        margin-bottom: 32px;
    }

    .mb-lg-35 {
        margin-bottom: 35px;
    }

    .mb-lg-40 {
        margin-bottom: 40px;
    }

    .mb-lg-45 {
        margin-bottom: 45px;
    }

    .mb-lg-50 {
        margin-bottom: 50px;
    }

    .mb-lg-m30 {
        margin-bottom: -30px;
    }

    .me-lg-20 {
        margin-right: 20px !important;
    }

    .me-lg-30 {
        margin-right: 30px !important;
    }

    .pt-lg-12,
    .py-lg-12 {
        padding-top: 12px;
    }

    .pb-lg-12,
    .py-lg-12 {
        padding-bottom: 12px;
    }

    .pt-lg-14,
    .py-lg-14 {
        padding-top: 14px;
    }

    .pb-lg-14,
    .py-lg-14 {
        padding-bottom: 14px;
    }

    .pt-lg-18,
    .py-lg-18 {
        padding-top: 18px;
    }

    .pb-lg-18,
    .py-lg-18 {
        padding-bottom: 18px;
    }

    .pt-lg-20,
    .py-lg-20 {
        padding-top: 20px;
    }

    .pb-lg-20,
    .py-lg-20 {
        padding-bottom: 20px;
    }

    .pt-lg-25,
    .py-lg-25 {
        padding-top: 25px;
    }

    .pb-lg-25,
    .py-lg-25 {
        padding-bottom: 25px;
    }

    .pt-lg-30,
    .py-lg-30 {
        padding-top: 30px;
    }

    .pb-lg-30,
    .py-lg-30 {
        padding-bottom: 30px;
    }

    .pt-lg-35,
    .py-lg-35 {
        padding-top: 35px;
    }

    .pb-lg-35,
    .py-lg-35 {
        padding-bottom: 35px;
    }

    .pt-lg-40,
    .py-lg-40 {
        padding-top: 40px;
    }

    .pb-lg-40,
    .py-lg-40 {
        padding-bottom: 40px;
    }

    .pt-lg-45,
    .py-lg-45 {
        padding-top: 45px;
    }

    .pb-lg-45,
    .py-lg-45 {
        padding-bottom: 45px;
    }

    .pt-lg-50,
    .py-lg-50 {
        padding-top: 50px;
    }

    .pb-lg-50,
    .py-lg-50 {
        padding-bottom: 50px;
    }

    .pt-lg-55,
    .py-lg-55 {
        padding-top: 55px;
    }

    .pb-lg-55,
    .py-lg-55 {
        padding-bottom: 55px;
    }

    .pt-lg-60,
    .py-lg-60 {
        padding-top: 60px;
    }

    .pb-lg-60,
    .py-lg-60 {
        padding-bottom: 60px;
    }

    .pt-lg-70,
    .py-lg-70 {
        padding-top: 70px;
    }

    .pb-lg-70,
    .py-lg-70 {
        padding-bottom: 70px;
    }

    .pt-lg-80,
    .py-lg-80 {
        padding-top: 80px !important;
    }

    .pb-lg-80,
    .py-lg-80 {
        padding-bottom: 80px !important;
    }

    .pt-lg-90,
    .py-lg-90 {
        padding-top: 90px !important;
    }

    .pb-lg-90,
    .py-lg-90 {
        padding-bottom: 90px !important;
    }

    .ps-lg-12,
    .px-lg-12 {
        padding-left: 12px !important;
    }

    .pe-lg-12,
    .px-lg-12 {
        padding-right: 12px !important;
    }

    .ps-lg-19,
    .px-lg-19 {
        padding-left: 19px !important;
    }

    .pe-lg-19,
    .px-lg-19 {
        padding-right: 19px !important;
    }

    .ps-lg-30,
    .px-lg-30 {
        padding-left: 30px !important;
    }

    .pe-lg-30,
    .px-lg-30 {
        padding-right: 30px !important;
    }

    .ps-lg-45,
    .px-lg-45 {
        padding-left: 45px !important;
    }

    .pe-lg-45,
    .px-lg-45 {
        padding-right: 45px !important;
    }

    .ps-lg-55,
    .px-lg-55 {
        padding-left: 55px !important;
    }

    .pe-lg-55,
    .px-lg-55 {
        padding-right: 55px !important;
    }

    .ps-lg-60,
    .px-lg-60 {
        padding-left: 60px !important;
    }

    .pe-lg-60,
    .px-lg-60 {
        padding-right: 60px !important;
    }
}


.bg-orange1 {
    background-color: #F59B00;
}

.bg-green {
    background-color: #2D8D4D;
}

.bg-violet {
    background-color: #89337F;
}

.bg-gray-dark {
    background-color: var(--se-gray-dark) !important;
}

.bg-6d {
    background-color: #6d6d6d;
}

.bg-d1 {
    background-color: #d1d1d1;
}

.bg-71 {
    background-color: #717171;
}

.bg-b4 {
    background-color: #B4B4B4;
}

.bg-f3 {
    background-color: #f3f3f3;
}

.bg-f4 {
    background-color: #f4f4f4;
}

.bg-cc {
    background-color: #cccccc;
}

.bg-f8 {
    background-color: #f8f8f8;
}

.bg-f9 {
    background-color: #f9f9f9;
}


.hover-bg-white:hover {
    background-color: #fff !important;
}

.hover-bg-primary:hover {
    background-color: var(--bs-primary) !important;
}

.hover-bg-primary-light:hover {
    background-color: #6fa3ff !important;
}

.hover-white:hover {
    color: #fff;
}

.hover-primary:hover {
    color: var(--bs-primary) !important;
}

.bg-grad-01 {
    background: linear-gradient(180deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 46.9%, rgba(179, 179, 179, 1) 47%, rgba(179, 179, 179, 1) 100%);
}

.bg-grad-02 {
    background: var(--bs-primary);
}

.bg-grad-03 {
    background: linear-gradient(180deg, #fff 0%, #fff 50%, #b4b4b4 50%, #b4b4b4 100%);
}

@media (min-width: 992px) {
    .bg-grad-01 {
        background: linear-gradient(180deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 64.9%, rgba(179, 179, 179, 1) 47%, rgba(179, 179, 179, 1) 100%);
    }

    .bg-lg-gray-dark {
        background-color: var(--se-gray-dark) !important;
    }

    .bg-grad-02 {
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 45px, rgba(63, 132, 255, 1) 45px, rgba(63, 132, 255, 1) 100%);
    }

    .bg-grad-03 {
        background: linear-gradient(180deg, #fff 0%, #fff 44%, #b4b4b4 44%, #b4b4b4 100%);
    }
}



.ff-mukta {
    font-family: 'Mukta', sans-serif;
}

.fs-10 {
    font-size: 0.625rem;
}

.fs-12 {
    font-size: 0.75rem;
}

.fs-13 {
    font-size: 0.8125rem;
}

.fs-14 {
    font-size: 0.875rem;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 1rem;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 1.125rem;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 1.25rem;
}

.fs-21 {
    font-size: 21px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.fs-25 {
    font-size: 25px;
}

.fs-28 {
    font-size: 28px;
}

.fs-30 {
    font-size: 30px;
}

.fs-32 {
    font-size: 32px;
}

.fs-34 {
    font-size: 34px;
}

.fs-40 {
    font-size: 40px;
}

.fs-42 {
    font-size: 42px;
}

.fs-44 {
    font-size: 44px;
}

.fs-46 {
    font-size: 46px;
}

.fs-50 {
    font-size: 50px;
}

.fs-52 {
    font-size: 52px;
}

.lh-11 {
    line-height: 1.1;
}

.lh-12 {
    line-height: 1.2;
}

.lh-13 {
    line-height: 1.3;
}

.lh-14 {
    line-height: 1.4;
}

.lh-16 {
    line-height: 1.6;
}

.lh-md {
    line-height: 1.7;
}

@media (min-width: 360px) {
    .fs-ss-20 {
        font-size: 20px;
    }
}

@media (min-width: 992px) {
    .lh-lg-1 {
        line-height: 1 !important;
    }

    .fs-lg-14 {
        font-size: 14px;
    }

    .fs-lg-16 {
        font-size: 16px;
    }

    .fs-lg-18 {
        font-size: 18px;
    }

    .fs-lg-20 {
        font-size: 20px;
    }

    .fs-lg-22 {
        font-size: 22px;
    }

    .fs-lg-24 {
        font-size: 24px;
    }

    .fs-lg-25 {
        font-size: 25px;
    }

    .fs-lg-29 {
        font-size: 29px;
    }

    .fs-lg-30 {
        font-size: 30px;
    }

    .fs-lg-32 {
        font-size: 32px;
    }

    .fs-lg-34 {
        font-size: 34px;
    }

    .fs-lg-36 {
        font-size: 36px;
    }

    .fs-lg-40 {
        font-size: 40px;
    }

    .fs-lg-50 {
        font-size: 50px;
    }
}



.text-green {
    color: #2D8D4D;
}

.text-87 {
    color: #878787;
}

.text-9e {
    color: #9e9e9e;
}

.text-cc {
    color: #cccccc;
}

.text-6e {
    color: #6e6e6e;
}

.text-4b8b53 {
    color: #4b8b53;
}

.text-0D63BD {
    color: #0D63BD;
}

.text-oneteam {
    color: var(--se-oneteam-color);
}

.text-se-primary {
    color: var(--se-primary);
}

@media (min-width: 992px) {
    .text-lg-black {
        color: #000 !important;
    }
}

.text-justify {
    text-align: justify;
}

.svg-icon {
    display: inline-block;
    fill: currentcolor;
}


.title {
    font-family: 'Mukta', sans-serif;
    font-weight: 700;
}




/* Header del sitio */
.site-header-landing {
    padding-top: 57px;
}

.navbar--se {
    --bs-navbar-color: #9e9e9e;
    --bs-navbar-hover-color: var(--bs-primary);
    --bs-navbar-nav-link-padding-x: 1rem;
    --bs-navbar-toggler-font-size: 1.25rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28158, 158, 158, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --bs-navbar-toggler-bg-size: 100%;
    --bs-navbar-toggler-focus-width: 0;
    --bs-navbar-active-color: var(--bs-primary);
    background: #fff;
    box-shadow: 0px 4px 17px 3px rgba(0, 0, 0, 0.25);
}

.navbar-nav--se {
    --bs-nav-link-padding-y: 0.8rem;
    --bs-nav-link-font-weight: 500;
    --bs-nav-link-font-size: 18px;
    height: 100vh;
}

.nav-item {
    padding: 0 28px;
    border-top: 1px solid #eee;
}

.nav-link:focus {
    color: var(--bs-navbar-color);
}

.nav-link:focus-visible {
    outline: 0;
    box-shadow: none;
}

.logo-se-header {
    width: 150px;
}

.nav-link-cta {
    padding: 8px 16px;
    border-radius: 12px;
}

.nav-link-cta-icon {
    width: 36px;
    height: 18px;
    margin-bottom: 4px;
}


.navbar-toggler[aria-expanded=true] {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28158, 158, 158, 1%29'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    --bs-navbar-toggler-bg-size: 70%;
}

.navbar-toggler-icon {
    width: 24px;
    height: 24px;
    background-size: var(--bs-navbar-toggler-bg-size);
}

@media (min-width: 992px) {
    .site-header-landing {
        padding-top: 65px;
    }

    .navbar-nav--se {
        --bs-nav-link-padding-y: 0.5rem;
        --bs-nav-link-font-size: 16px;
        height: auto;
    }

    .nav-item {
        padding: 0;
        border-top: none;
    }

    .logo-se-header {
        width: 210px;
    }
}



.dropdown-menu--se {
    --bs-dropdown-min-width: auto;
    /*13rem;*/
    --bs-dropdown-padding-y: 0;

    --bs-dropdown-font-size: 0.9375rem;
    --bs-dropdown-color: #9e9e9e;

    --bs-dropdown-border-radius: 2rem;
    --bs-dropdown-border-width: 0;
    --bs-dropdown-inner-border-radius: 2rem;

    --bs-dropdown-link-color: #9e9e9e;
    --bs-dropdown-link-hover-color: var(--bs-primary);
    --bs-dropdown-link-hover-bg: transparent;
    --bs-dropdown-link-active-color: var(--bs-primary);
    --bs-dropdown-link-active-bg: #fff;

    --bs-dropdown-item-padding-x: 0;

    padding-bottom: 1.5rem;
    border-radius: 0 0 var(--bs-dropdown-border-radius) var(--bs-dropdown-border-radius);

    transition: all linear .5s;
}

.dropdown-menu--paises {
    --bs-dropdown-min-width: 73px;
    --bs-dropdown-item-padding-x: 10px;
    --bs-dropdown-border-radius: 20px;
    padding-bottom: 10px;
}


.dropdown-toggle--menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-toggle--menu::after {
    --bs-btn-arrow-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 8' fill='none'%3e%3cpath stroke='%239E9E9E' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2' d='M15 1 9.237 6.512c-.68.65-1.794.65-2.474 0L1 1'/%3e%3c/svg%3e");
    content: "";
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
    border: 0;
    background: #0000 var(--bs-btn-arrow-bg) center/1rem auto no-repeat;
    width: 16px;
    height: 8px;
    transition: transform 0.2s ease-in-out;
}

/*
.dropdown-toggle--menu:hover::after {
	--bs-btn-arrow-bg:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 8' fill='none'%3e%3cpath stroke='%233F84FF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2' d='M15 1 9.237 6.512c-.68.65-1.794.65-2.474 0L1 1'/%3e%3c/svg%3e");
}
*/
.dropdown-toggle--menu.show::after {
    --bs-btn-arrow-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 8' fill='none'%3e%3cpath stroke='%233F84FF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2' d='M15 1 9.237 6.512c-.68.65-1.794.65-2.474 0L1 1'/%3e%3c/svg%3e");
    transform: rotate(180deg);
}

@media (min-width: 992px) {

    .dropdown-menu--se {
        --bs-dropdown-padding-y: 1rem;
        --bs-dropdown-item-padding-x: 1.1rem;
        padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    }

    .dropdown-toggle--menu::after {
        background-size: 60%;
    }
}





/* Footer del sitio */
.site-footer {
    border-top: 10px solid var(--bs-primary);
    padding: 40px 0 10px;
    background-color: #363636;
    font-size: 14px;
}

.logo-se-footer {
    margin-bottom: 18px;
    width: 240px;
}

.icon-social-footer {
    width: 32px;
    height: 32px;
    color: #fff;
}

.badge-store-footer {
    height: 40px;
}

.badge-store-banner {
    height: 40px;
}

.badge-store-menu {
    height: 38px;
}

.icon-datafiscal {
    width: 25px;
}

.country-selector {
    color: #fff;
}

.country-selector:hover {
    color: var(--bs-link-color);  
}


@media (min-width: 768px) {
    .site-footer {
        border-top: 20px solid var(--bs-primary);
    }

    .icon-social-footer {
        width: 26px;
        height: 26px;
    }

    .badge-store-footer {
        width: 120px;
        height: auto;
    }

    .icon-datafiscal {
        width: 50px;
    }
}

@media (min-width: 992px) {
    .badge-store-banner {
        height: 50px;
    }

    .badge-store-menu {
        width: 120px;
        height: auto;
    }
}




.sec-hero {
	position:relative; 
	overflow:hidden;
	padding:35px 10px 240px;
	background:url(../img/bg-hero-mobile.png) no-repeat center 100.5%;
	background-size:430px;
}

@media (min-width: 768px) {
	.sec-hero {
		padding:70px 0 120px;
		background:url(../img/bg-hero-desktop.png) no-repeat center bottom;
		background-size:150%;
	}
}

@media (min-width: 992px) {
	.sec-hero {background-size:auto;}
}


.icon-motivos {
    width: 70px;
    height: 70px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 1000px;
}

.logo-ssn {
    width: 160px;
}

.logo-insurtech {
    width: 100px;
}

.icon-star {
    width: 17px;
    height: 17px;
    fill: #ccc;
}

.icon-star-on {
    fill: #F59B00;
}

@media (min-width: 992px) {
    .icon-motivos {
        width: 90px;
        height: 90px;
    }

    .logo-ssn {
        width: 200px;
    }

    .logo-insurtech {
        width: 140px;
    }
}




/* seguros en home */
.item-seguro {
    width: 112px;
    color: var(--bs-primary);
}

.item-seguro:hover .icon-seguros-wrapper {
    background-color: var(--bs-primary);
    color: #fff;
}

.icon-seguros-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 12px;
    background-color: #F0F0F0;
    border-radius: 1000px;
    border: 1px solid var(--bs-primary);
    box-shadow: 0px 0px 0px 2px #fff, 0px 4px 4px 0px #ccc;
    width: 70px;
    height: 70px;
}

.icon-seguros {
    width: 70%;
    height: 70%;
}

@media (min-width: 992px) {
    .item-seguro {
        width: 140px;
    }

    .icon-seguros-wrapper {
        width: 90px;
        height: 90px;
    }
}

@media (min-width: 1200px) {
    .item-seguro {
        width: 165px;
    }
}


/* seguros en landings pymes e individuos */
.item-seguro-alt {
    width: 120px;
    color: var(--se-primary);
}

.item-seguro-alt:hover .icon-seguros-alt-wrapper {
    background-color: var(--bs-primary);
    color: #fff;
}

.icon-seguros-alt-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 12px;
    background-color: #F0F0F0;
    border-radius: 1000px;
    border: 2px solid var(--bs-primary);
    box-shadow: 0px 0px 0px 1px #fff, 0px 3px 7px 0px #ccc;
    width: 78px;
    height: 78px;
}

.icon-seguros-alt {
    width: 60%;
    height: 60%;
}

@media (min-width: 992px) {
    .item-seguro-alt {
        width: 120px;
    }

    .icon-seguros-alt-wrapper {
        width: 105px;
        height: 105px;
    }
}



/**/
.g-6,
.gy-6 {
    --bs-gutter-y: 4.5rem;
}

@media (min-width: 992px) {
    .gx-lg-80 {
        --bs-gutter-x: 80px;
    }
}




.motivos-wrapper {
    height: 100%;
    padding: 10px 10px 16px;
    border-radius: 22px;
    border: 4px solid var(--bs-primary);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: 40px;
}

.motivos-icon-wrapper {
    margin-bottom: 15px;
    margin-top: -55px;
}

@media (min-width: 992px) {
    .motivos-wrapper {
        padding: 10px 30px 30px;
    }
}




/**/
.mock1-img {
    height: 360px;
    margin-bottom: -3rem;
}

/*430*/

@media (min-width: 768px) {
    .mock2-img {
        position: absolute;
        max-height: 140%;
        right: -170px;
        bottom: -80px;
    }
}

@media (min-width: 992px) {
    .mock2-img {
        right: -210px;
        bottom: -95px;
    }
}




/**/
.slide-aseguradoras {}

.logo-aseguradora {
    height: 36px;
    display: block;
    margin: 0 auto;
}

@media (min-width: 992px) {
    .logo-aseguradora {
        height: 40px;
    }
}




/**/
.slide-comentarios {
    margin: 0 -12px;
    padding-bottom: 40px;
}

@media (max-width: 778px){
    .slide-comentarios .splide__pagination{
        bottom: -32px;
    }
}

.slide-comentarios .splide__pagination__page {
    margin: 3px 8px;
    background: #aaa;
}

.slide-comentarios .splide__pagination__page.is-active {
    background: var(--bs-primary);
}

.dots-white .splide__pagination__page {
    top: 8px; /* Ejemplo de color */
}
.dots-white .splide__pagination__page.is-active {
    background-color: #fff; /* Ejemplo de color */
}

.comms-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 10px 12px;
    margin: 0 6px 10px;
    height: calc(100% - 10px);
    /* resta sombra */
}



/**/
.slide-noticias {
    margin: 0 -12px;
    padding-bottom: 40px;
}

.slide-noticias .splide__pagination__page {
    margin: 3px 8px;
    background: #aaa;
}

.slide-noticias .splide__pagination__page.is-active {
    background: var(--bs-primary);
}


.news-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    width: 95%;
    margin: 0 auto;
    padding: 30px 15px 15px 15px;
    background-color: #F0F0F0;
    border: 1px solid #fff;
    border-radius: 0 0 70px 20px;
    box-shadow: 5px 8px 16px 0px rgba(0, 0, 0, 0.33);
    height: 100%;
}

.news-title {
    text-align: left;
    margin-top: -60px;
    margin-bottom: 20px;
    background-color: var(--bs-primary);
    color: #fff;
    padding: 10px;
    border-radius: 20px 20px 0 0;
}

.news-copy {
    text-align: left;
}

.news-wrapper a {
    margin-right: 40px;
}

.news-title-empty {
    height: 40px;
}

@media (min-width: 992px) {
    .news-wrapper {
        width: 90%;
        border-radius: 0 0 90px 20px;
    }
}



/**/
.slide-espiritu {
    margin: 0 -12px;
    padding-bottom: 50px;
}

.slide-espiritu .splide__pagination {
    bottom: 22px;
}

.slide-espiritu .splide__pagination__page {
    margin: 3px 8px;
    background: #D6D6D6;
}

.slide-espiritu .splide__pagination__page.is-active {
    background: #fff;
    opacity: 1;
}



/**/
.slide-mobile-only {
    margin: 0 -12px;
    padding-bottom: 50px;
}

.slide-mobile-only .splide__pagination {
    bottom: 22px;
}

.slide-mobile-only .splide__pagination__page {
    margin: 3px 8px;
    background: #D6D6D6;
}

.slide-mobile-only .splide__pagination__page.is-active {
    background: #fff;
    opacity: 1;
}








/* word slider */
.word-slider {
    display: inline-block;
    height: 37px;
    overflow: hidden;
    vertical-align: middle;
}

.word-slider__words {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-duration: 8s;
}

.word-slider__words--6w {
    animation-name: wordSlider6Words;
    animation-duration: 15s;
}

.word-slider__words--4w {
    animation-name: wordSlider4Words;
}

.word-slider__words--3w {
    animation-name: wordSlider3Words;
}

.word-slider__words--2w {
    animation-name: wordSlider2Words;
}

.word-slider__word {
    line-height: 37px;
}

@media (min-width: 992px) {
    .word-slider {
        height: 55px;
        margin-bottom: 6px;
    }

    .word-slider__word {
        line-height: 55px;
    }

    .word-slider--sm {
        height: 50px;
        margin-bottom: 6px;
    }

    .word-slider__word--sm {
        line-height: 50px;
    }
}


@keyframes wordSlider6Words {

    0%,
    14% {
        transform: translateY(0%);
    }

    16%,
    30% {
        transform: translateY(-14.28%);
    }

    32%,
    46% {
        transform: translateY(-28.57%);
    }

    48%,
    62% {
        transform: translateY(-42.85%);
    }

    64%,
    78% {
        transform: translateY(-57.13%);
    }

    80%,
    94% {
        transform: translateY(-71.41%);
    }

    100% {
        transform: translateY(-85.69%);
    }
}

@keyframes wordSlider4Words {

    0%,
    20% {
        transform: translateY(0%);
    }

    25%,
    45% {
        transform: translateY(-20%);
    }

    50%,
    70% {
        transform: translateY(-40%);
    }

    75%,
    95% {
        transform: translateY(-60%);
    }

    100% {
        transform: translateY(-80%);
    }
}

@keyframes wordSlider3Words {

    0%,
    27% {
        transform: translateY(0%);
    }

    33%,
    60% {
        transform: translateY(-25%);
    }

    66%,
    93% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(-75%);
    }
}

@keyframes wordSlider2Words {

    0%,
    45% {
        transform: translateY(0%);
    }

    50%,
    95% {
        transform: translateY(-33%);
    }

    100% {
        transform: translateY(-66%);
    }
}



/**/
.can-toggle {
    position: relative;
    display: inline-block;
}

.can-toggle input[type=checkbox] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.can-toggle label {
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    width: 296px;
}

.can-toggle label .can-toggle__switch {
    position: relative;
    flex: 0 0 100%;
    height: 40px;
    background: #ccc;
    border-radius: 32px;
}

.can-toggle label .can-toggle__switch:before,
.can-toggle label .can-toggle__switch:after {
    position: absolute;
    width: 148px;
    padding: 0 3px 0 0;
    font-size: 20px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    border-radius: 32px;
    cursor: pointer;
}

.can-toggle label .can-toggle__switch:before {
    top: 0;
    right: 0;
    content: attr(data-checked);

}

.can-toggle label .can-toggle__switch:after {
    top: 0;
    left: 0;
    content: attr(data-unchecked);
    background: var(--bs-primary);
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);

}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:before {
    content: attr(data-unchecked);
    left: 0;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:after {
    content: attr(data-checked);
    transform: translate3d(100%, 0, 0);
}


@media (min-width: 992px) {
    .can-toggle label {
        width: 360px;
    }

    .can-toggle label .can-toggle__switch {
        height: 48px;
    }

    .can-toggle label .can-toggle__switch:before,
    .can-toggle label .can-toggle__switch:after {
        width: 180px;
        padding: 0 3px 0 0;
        font-size: 24px;
        line-height: 48px;
    }
}



/* scroll to top */
.to-top {
    position: absolute;
    top: -37px;
    right: 20px;
    z-index: 1;
}

.to-top-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bs-primary);
    border-radius: 1000px;
    border: 1px solid var(--bs-primary);
    box-shadow: 0px 0px 0px 1px #fff, 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 34px;
    height: 34px;
}

.to-top-icon {
    height: 50%;
}

@media (min-width: 992px) {
    .to-top {
        top: -52px;
        right: 30px;
    }

    .to-top-wrapper {
        width: 50px;
        height: 50px;
    }
}



/* shapes */
.pos01 {
    position: absolute;
    top: -85px;
    left: -130px;
    width: 171px;
    height: 194px;
    fill: rgba(246, 246, 246, 0.70);
}

.pos02 {
    position: absolute;
    top: 100px;
    left: -39px;
}

.pos03 {
    position: absolute;
    top: 200px;
    left: -320px;
}

.pos04 {
    position: absolute;
    top: 200px;
    right: -290px;
}

.pos05 {
    position: absolute;
    top: 40px;
    left: -330px;
}

.pos06 {
    position: absolute;
    top: -115px;
    right: 0;
    z-index: 1;
}

.pos07 {
    position: absolute;
    bottom: 70px;
    left: -330px;
}

.pos08 {
    position: absolute;
    top: 280px;
    left: -200px;
    z-index: -1;
}

.pos09 {
    position: absolute;
    bottom: 80px;
    right: -335px;
    z-index: -1;
}

.pos10 {
    position: absolute;
    top: 80px;
    left: -330px;
    z-index: -1;
}

.pos11 {
    position: absolute;
    top: -70px;
    left: -215px;
}

.pos12 {
    position: absolute;
    bottom: 16px;
    right: -215px;
}

.pos13 {
    position: absolute;
    top: 340px;
    left: -320px;
}

.pos14 {
    position: absolute;
    top: 260px;
    right: -290px;
}

.pos15 {
    position: absolute;
    top: 140px;
    left: -480px;
}

.pos16 {
    position: absolute;
    top: -200px;
    right: 0;
}


@media (min-width: 992px) {
    .pos01 {
        top: -180px;
        left: -165px;
        width: 243px;
        height: 264px;
    }
}


.rounded-8 {
    border-radius: 8px;
}

.rounded-10 {
    border-radius: 10px;
}

.rounded-18 {
    border-radius: 18px;
}

.shadow-1 {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
}

.shadow-2 {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}

.shadow-3 {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
}

.shadow-4 {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}

.border-878FA3 {
    border-color: #878FA3 !important;
}

@media (min-width: 992px) {
    .border-lg-none {
        border: none !important;
    }
}



.item-ejes {
    width: 160px;
}

.icon-ejes {
    width: 67px;
    height: 67px;
}

.icon-oneteam {
    width: 80px;
    height: 80px;
}

.icon-historia {
    width: 45px;
    min-width: 45px;
    height: 42px;
}

.icon-tecno-propia {
    width: 43px;
    height: 43px;
}

@media (min-width: 992px) {
    .item-ejes {
        width: 230px;
    }

    .icon-ejes {
        width: 83px;
        height: 83px;
    }

    .icon-oneteam {
        width: 90px;
        height: 90px;
    }

    .icon-historia {
        width: 70px;
        min-width: 70px;
        height: 65px;
    }

    .icon-tecno-propia {
        width: 70px;
        height: 70px;
    }
}




.fig-hero-contacto {width:257px; height:auto; max-width:100%;}

@media (min-width: 992px) {
	.fig-hero-contacto {width:530px;}
	
	.bg-lg-figura04 {min-height:304px; background-image:url(../img/figura_04.png); background-repeat:no-repeat; background-position:right center;}
	
}





/**/
.slide-comentarios {
    margin: 0 -12px;
    padding-bottom: 40px;
}

.slide-comentarios .splide__pagination__page {
    margin: 3px 8px;
    background: #aaa;
}

.slide-comentarios .splide__pagination__page.is-active {
    background: var(--bs-primary);
}


.comms-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 10px 12px;
    margin: 0 6px 10px;
    height: calc(100% - 10px);
    /* resta sombra */
}



/**/
.slide-novedades {
    margin: 0 -12px;
    padding-bottom: 40px;
}

.slide-novedades .splide__pagination__page {
    margin: 3px 8px;
    background: #aaa;
}

.slide-novedades .splide__pagination__page.is-active {
    background: var(--bs-primary);
}

.card-nota {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 95%;
    height: 100%;
    margin: 0 auto;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
}

.card-nota-header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 235px;
    padding: 18px 24px;
}

.card-nota-body {
    flex: 1 1 auto;
    padding: 10px 25px;
}

.card-nota-footer {
    padding: 0 25px 25px;
}

.card-nota-image {
    position: absolute;
    inset: 0px;
    display: block;
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}


@media (min-width: 992px) {
    .card-nota {
        width: 90%;
    }

    .card-nota-header {
        height: 270px;
    }

    .card-nota-body {
        padding: 12px 25px;
    }

    .card-nota-footer {
        padding: 0 25px 28px;
    }
}




.transition-base {
    transition: color .2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}


.animate__animated.animate__delay-05s {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-delay: calc(var(--animate-delay)*0.5);
    animation-delay: calc(var(--animate-delay)*0.5)
}


.inset-0 {
    inset: 0;
}

.blend-multipy {
    mix-blend-mode: multiply;
}



/* faq */
/* bs accordion fix */
.accordion-button:not(.collapsed) {
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
    margin-bottom: 0;
}



.accordion-faq-topic {
    --bs-accordion-color: #000;
    --bs-accordion-border-width: 0;
    --bs-accordion-border-radius: 10px;
    --bs-accordion-inner-border-radius: calc(var(--bs-accordion-border-radius) - (var(--bs-border-width)) - (var(--bs-border-width)));
    --bs-accordion-btn-padding-x: 16px;
    --bs-accordion-btn-padding-y: 10px;
    --bs-accordion-btn-color: #fff;
    --bs-accordion-btn-bg: #3F84FF;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233F84FF'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-body-padding-x: 20px;
    --bs-accordion-body-padding-y: 0;
    --bs-accordion-active-color: #3F84FF;
    --bs-accordion-active-bg: #fff;
}

.accordion-faq-topic>.accordion-item:not(:first-of-type) {
    border-top: var(--bs-accordion-border-width) solid;
}

.accordion-faq-topic .accordion-button {
    font-size: 18px;
    font-weight: bold;
}


.accordion-faq {
    --bs-accordion-color: #000;
    --bs-accordion-border-color: #000;
    --bs-accordion-btn-padding-x: 0;
    --bs-accordion-btn-padding-y: 14px;
    --bs-accordion-btn-color: #000;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233F84FF'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233F84FF'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-body-padding-x: 0;
    --bs-accordion-body-padding-y: 8px;
    --bs-accordion-active-color: #3F84FF;
    --bs-accordion-active-bg: #fff;
}

/* .accordion-faq.accordion-flush .accordion-item:first-child {border-top:var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);;} */
.accordion-faq .accordion-button {
    font-size: 18px;
    font-weight: bold;
}

.accordion-faq .accordion-body {
    padding-top: 0;
}

.icon-faq-topic {
    width: 55px;
    min-width: 55px;
    height: 39px;
    margin-right: 10px;
}



/* */
.icon-banner-sustentabilidad {
    width: 58px;
    height: 58px;
}

@media (min-width: 992px) {
    .icon-banner-sustentabilidad {
        width: 80px;
        height: 80px;
    }
}



/* */
.arrow {}

.arrow-white {
    --border-color: #ffffff;
}

.arrow-grey {
    --border-color: #6d6d6d;
}

.arrow:after {
    --border-width-y: 15px;
    --border-width-x: 9px;
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border: solid transparent;
    pointer-events: none;
}

.arrow-bottom:after {
    top: 100%;
    left: 50%;
    margin-left: calc(-1 * var(--border-width-y));
    border-width: var(--border-width-y) var(--border-width-x);
    border-top-color: var(--border-color);
}


@media (max-width: 991.98px) {
    .arrow-uptolg-bottom:after {
        top: 100%;
        left: 50%;
        margin-left: calc(-1 * var(--border-width-y));
        border-width: var(--border-width-y) var(--border-width-x);
        border-top-color: var(--border-color);
    }
}

@media (min-width: 992px) {
    .arrow-lg-right:after {
        left: 100%;
        top: 50%;
        margin-top: calc(-1 * var(--border-width-y));
        border-width: var(--border-width-x) var(--border-width-y);
        border-left-color: var(--border-color);
    }
}



/* hero quienes somos */
.bg-hero-03 {
	background-image:url(../img/figura-hero-03.svg); background-repeat:no-repeat;
	background-position:center 101%;
	background-size: 360px auto;
}


@media (min-width: 992px) {
    .bg-hero-03 {
        background-position: 120% 101%;
        background-size: 530px auto;
    }
}

@media (min-width: 1200px) {
    .bg-hero-03 {
        background-position: 115% 101%;
        background-size: 560px auto;
    }
}

@media (min-width: 1500px) {
    .bg-hero-03 {
        background-position: 90% 101%;
    }
}

/* hero oneteam */
.bg-hero-04 {
	background-image:url(../img/figura-hero-04.svg); background-repeat:no-repeat;
	background-position:center 100%;
	background-size: 290px auto;
}

@media (min-width: 992px) {
    .bg-hero-04 {
        background-position: 113% 101%;
        background-size: 485px auto;
    }
}

@media (min-width: 1200px) {
    .bg-hero-04 {
        background-position: 109% 101%;
    }
}

@media (min-width: 1500px) {
    .bg-hero-04 {
        background-position: 81% 101%;
    }
}



/* badge reconocimiento empleador*/
.ban-empleador-badge {
    position: absolute;
    top: -60px;
    left: calc(50% - 50px);
    width: 101px;
    height: 139px;
}

@media (min-width: 992px) {
    .ban-empleador-badge {
        top: calc(50% - 70px);
        left: -65px;
    }
}



/* banner insitucional */
 .bg-inst01 {
    padding: 20px 0 110px;
    background: #3F84FF url(../img/bg-inst01.png) no-repeat 50% bottom;
    background-size: cover;
} 

@media (min-width: 576px) {
    .bg-inst01 {
        padding-bottom: 150px;
    }
}

 @media (min-width: 992px) {
    .bg-inst01 {
        padding: 35px 0 55px;
        background: #3F84FF url(../img/bg-inst02.png) no-repeat right 50%;
    }
}



.icon-18 {
    width: 18px;
    height: 18px;
}

@media (min-width: 992px) {
    .icon-lg-28 {
        width: 28px;
        height: 28px;
    }
}


@media (min-width: 992px) {
    .fig-position-05 {
        position: absolute;
        width: 780px;
        bottom: 0;
        left: 445px;
    }

    .fig-position-06 {
        position: absolute;
        width: 400px;
        bottom: 0;
        left: 650px;
    }

    .fig-position-09 {
        position: absolute;
        width: 600px;
        bottom: 0;
        left: 450px;
    }
}

@media (min-width: 1200px) {
    .fig-position-05 {
        left: 575px;
    }

    .fig-position-06 {
        left: 720px;
    }

    .fig-position-09 {
        left: 650px;
    }
}




.bullet-circle-primary {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='%233F84FF'/%3E%3C/svg%3E%0A");
    background-position: left .4em;
    background-repeat: no-repeat;
}

.bullet-check-primary {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 26 26'%3E%3Cpath fill='%23fff' d='M12.983 25.995C5.813 25.978-.017 20.134 0 12.98.017 5.811 5.863-.017 13.017 0c7.17.017 13 5.861 12.983 13.014-.017 7.17-5.863 12.998-13.017 12.98Zm-1.957-10.893c-.644-.646-1.234-1.24-1.83-1.83-.408-.404-.804-.824-1.238-1.2-.442-.382-.982-.487-1.548-.344-1.389.35-1.891 1.997-.888 3.043 1.37 1.43 2.788 2.813 4.198 4.203.18.179.42.326.66.41.773.273 1.44.06 2.011-.512 2.655-2.658 5.315-5.315 7.97-7.973.09-.089.174-.185.253-.284.677-.854.415-2.166-.537-2.698-.782-.435-1.627-.298-2.312.384-2.184 2.183-4.369 4.368-6.552 6.553-.065.065-.113.147-.188.248h.001Z'/%3E%3Cpath fill='%233F84FF' d='M11.026 15.104c-.644-.646-1.234-1.24-1.83-1.83-.408-.404-.804-.824-1.238-1.2-.442-.382-.982-.487-1.548-.345-1.389.35-1.891 1.998-.888 3.044 1.37 1.43 2.788 2.813 4.198 4.203.18.179.42.326.66.41.773.273 1.44.06 2.011-.513 2.655-2.658 5.314-5.314 7.97-7.972.09-.089.174-.185.253-.284.677-.854.415-2.166-.537-2.698-.782-.435-1.627-.298-2.312.384-2.184 2.183-4.369 4.368-6.552 6.553-.065.065-.113.147-.188.248h.001Z'/%3E%3C/svg%3E%0A");
    background-position: left 1em;
    background-repeat: no-repeat;
    background-size: 1.3em;
}

.list-bullet-md {}

.list-bullet-md li {
    padding-top: 15px;
    padding-bottom: 15px;
}




/* hero landing pymes e individuos */
.bg-hero-pymes {
	background:url(../img/bg-hero-pymes-mobile-2x.png) no-repeat 50% 100%;
	background-size: 660px auto;
	padding-top:40px; padding-bottom:240px;
}
.bg-hero-individuos {
	background:url(../img/bg-hero-individuos-mobile-2x.png) no-repeat 50% 100%;
	background-size: 660px auto;
	padding-top:40px; padding-bottom:280px;
}

@media (min-width: 992px) {

    .bg-hero-pymes,
    .bg-hero-individuos {
        background-image: none;
        padding-top: 110px;
        padding-bottom: 180px;
    }

    .bg-hero-pymes::after,
    .bg-hero-individuos::after {
        content: "";
        width: 735px;
        height: 735px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        bottom: -652px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    .fig-position-pymes {
        position: absolute;
        width: 1200px;
        bottom: 0;
        left: 560px;
    }

    .fig-position-individuos {
        position: absolute;
        width: 1200px;
        bottom: -20px;
        left: 540px;
    }
}



.landing-white-circle::after {
    content: "";
    width: 210px;
    height: 210px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    bottom: -45px;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
}

@media (min-width: 992px) {
    .landing-white-circle::after {
        width: 440px;
        height: 440px;
        bottom: -64px;
    }
}


@media (min-width: 992px) {
    .bg-line-lg::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 50px;
        width: 70%;
        height: 2px;
        margin: 0 auto;
        background-color: var(--bs-primary);

    }
}

.splide__pagination {
    display: flex;
    justify-content: center;
    position: absolute;
    height: fit-content;
    bottom: -20px;
}

.splide__pagination__page {
    width: 8px;
    height: 8px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.splide__pagination__page.is-active {
    background-color: #3f84ff; /* Cambia el color cuando el dot está activo */
}

#formulario {
    scroll-margin-top: 60px; /* Ajusta el valor según la altura del header */
}