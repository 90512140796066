@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");


html {
  margin: 0;
  font-family: 'DM Sans', sans-serif !important;
  /* height: 100%; */
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
}

/* root variables bs */
:root {
  --bs-primary: #2c7dfd;
  --bs-primary-rgb: 44, 125, 253;

  --bs-font-sans-serif: "DM Sans", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  --bs-body-color: #000000;
  --bs-body-color-rgb: 00, 00, 00;

  --bs-link-color: #2c7dfd;
  --bs-link-color-rgb: 44, 125, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;

  --bs-border-radius: 4px;
  --bs-border-color: #dee2e6;
  --bs-border-radius-sm: 3px;

  --bs-form-invalid-color: #da0d0d;
  --bs-form-invalid-border-color: #da0d0d;
}

/* root variables SE */
:root {
  --se-gray-light: #f8f8f8;
  --se-gray-dark: #a5a5a5;
  --se-gray-aux: #343434;

  --se-header-height: 61px;
}

/* Redefino */
.btn {
  --bs-btn-padding-x: 15px;
  --bs-btn-padding-y: 4px;
  --bs-btn-font-size: 20px;
  --bs-btn-font-weight: 700;
  --bs-btn-disabled-opacity: 1;
}

@media (min-width: 768px) {
  .btn {
    --bs-btn-font-size: 24px;
  }
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--se-gray-dark);
  --bs-btn-disabled-border-color: var(--se-gray-dark);
}

.list-group {
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.7rem;
}

.cod-postal-column {
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}

a {
  text-decoration: none;
}

.form-control {
  padding: 7px 16px;
}

.form-control::placeholder {
  color: #d9d9d9;
}

.form-control:focus {
  border-color: var(--bs-primary);
  box-shadow: none;
}

.form-label {
  line-height: 1.25;
  margin-bottom: 8px;
}

.form-select {
  padding: 7px 2.25rem 7px 0.75rem;
}

.form-select:focus {
  border-color: var(--bs-primary);
  box-shadow: none;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 15px;
}

.input-group-text {
  padding: 7px 5px 7px 10px;
  color: var(--bs-primary);
  background-color: #fff;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  padding-left: 5px;
  border-left: 0;
}

.input-group:focus-within span {
  border-color: var(--bs-primary);
}

.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
  padding-right: 16px;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}

.invalid-feedback {
  text-align: left;
  font-size: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* globales */
.bg-White {
  background-color: #FFFFFF;
}

.bg-gray-light {
  background-color: var(--se-gray-light);
}

.bg-gray-dark {
  background-color: var(--se-gray-dark);
}

.bg-d9d9d9 {
  background-color: #d9d9d9;
}

.fs-1r {
  font-size: 1rem;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-30 {
  font-size: 30px;
}

.a-dark {
  color: #343434;
}

.a-dark:hover {}

.bi {
  display: inline-block;
  vertical-align: 0;
  fill: currentcolor;
}

/* bs icons */
.icon {
  display: inline-block;
  fill: currentcolor;
}

@media (min-width: 576px) {
  .mw-sm-350 {
    max-width: 350px;
  }

  .mw-sm-450 {
    max-width: 450px;
  }
}

.form-switch-inline {
  display: inline-block;
  min-height: auto;
}

.se-tooltip {
  /*--bs-tooltip-max-width: 200px;*/
  --bs-tooltip-bg: var(--bs-primary);
  --bs-tooltip-font-size: 14px;
}

/* Header del sitio */
.site-header {
  padding: 18px 0;
  text-align: center;
  background-color: #fff;
}

.site-header h1 {
  font-size: 1rem;
  margin: 0;
  line-height: 1;
}

.site-header .logo-se {
  width: 160px;
}

.tel {
  display: inline-block;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  color: #343434;
}

.icon {
  display: inline-block;
  width: 9px;
  height: 12px;
}

@media (min-width: 768px) {
  .site-header {
    padding: 18px 0 14px;
  }

  .tel {
    margin-top: 0;
    font-size: 16px;
  }

  .icon {
    width: 11px;
    height: 15px;
  }
}

/* Row compañias */
.slide-cias {
  /*padding: 31px 0 0 0 ;*/
  margin-top: 12px;
  margin-bottom: 12px;
}

.slide-cias img {
  display: block;
  width: 50%;
  max-width: 150px;
  margin: 0 auto;
}

.slide-cias .splide__arrows,
.slide-cias .splide__pagination {
  display: none;
}

/* Footer del sitio */

.footer {
  margin-top: auto;
}

.site-footer {
  padding: 15px;
  background-color: #363636;
  font-size: 14px;
}

.site-footer .logo-se {
  width: 99px;
}

/* Header del cotizador */
.cot-header {
  padding: 15px 0;
  overflow: hidden;
}

.cot-header {
  border-radius: 0px 0px 0px 0px;
  margin: 0 0 0px 0px;
}

/* xs - sm only */
.cot-header .title {
  margin: 0 0 14px;
  font-weight: 700;
  font-size: 28px;
  color: #fff;
  line-height: 1;
}

.cot-header .steps {
  position: relative;
  min-height: 50px;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
}

.cot-header .steps:before {
  content: "";
  position: absolute;
  left: -150px;
  right: -150px;
  height: 2px;
  background-color: #fff;
}

.cot-header .step {
  width: 31px;
  height: 31px;
  line-height: 30px;
  border: 1px solid #fff;
  border-radius: 100%;
  z-index: 1;
  background-color: var(--bs-primary);
}

.cot-header .step.step-final {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.cot-header .steps .car {
  position: absolute;
  display: none;
  fill: currentcolor;
  width: 64px;
  height: 31px;
  color: #fff;
}

.cot-header .steps.step1-on .step1,
.cot-header .steps.step2-on .step2 {
  color: var(--bs-primary);
  background-color: #fff;
}

.cot-header .steps.step1-on .car,
.cot-header .steps.step2-on .car {
  top: -8px;
  left: 52px;
  display: inline-block;
}

.cot-header .steps.step1-on:before {
  left: 0;
  right: 0;
}

.cot-header .steps.step1-on .step-final {
  display: block;
}

.cot-header .steps.step2-on:before {
  right: 0;
  left: 0;
}

.cot-header .steps.step2-on .step1 {
  display: none;
}

@media (max-width: 768px) {

  .cot-header .steps.step2-on .car {
    left: 55%;
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .cot-header {
    padding: 24px 0 15px;
    border-radius: 0;
    margin: 0;
  }

  .cot-header .title {
    margin: 0 0 20px;
    font-size: 24px;
  }

  .cot-header .steps:before {
    left: 0;
    right: 0;
  }

  .cot-header .steps.step1-on .step-final,
  .cot-header .steps.step2-on .step1 {
    display: block;
  }

  .cot-header .step.step-final {
    display: block;
  }

  .cot-header .steps.step2-on .car {
    left: 55%;
    display: inline-block;
  }
}

/* Cargando */
.cot-cargando {
  padding: 35px 0 120px;
  color: #fff;
}

.cot-cargando .title {
  margin: 0 0 30px;
  font-weight: 700;
  font-size: 40px;
  color: #fff;
}

.cot-cargando .lst01 {
  margin: 0 0 1rem;
  padding: 0;
  list-style: none;
  font-size: 16px;
  font-weight: 500;
}

.cot-cargando .lst01 li {
  display: inline-block;
}

.cot-cargando .lst01 li:not(:last-child) {
  margin-right: 8px;
}

.cot-cargando .lst01 li:not(:last-child)::after {
  content: "|";
  padding-left: 10px;
}

.cot-cargando .track {
  position: relative;
  margin: 110px 0 70px;
  border-bottom: 2px solid rgb(255 255 255 / 60%);
  overflow-x: clip;
}

.cot-cargando .objs {
  position: absolute;
  bottom: 0;
  display: inline-block;
  fill: currentcolor;
  width: 132px;
  height: 64px;
}

.cot-cargando .obj-1 {
  right: 50px;
  color: #fff;
}

.cot-cargando .copy {
  font-size: 19px;
  line-height: 1.3;
}

.cot-cargando .copy .dest {
  display: block;
  margin-top: 14px;
  font-size: 28px;
  font-weight: 700;
}

.cot-cargando .obj-1 {
  animation: anim-linear 5s linear infinite;
}

@media (min-width: 768px) {
  .cot-cargando {
    padding: 40px 0 120px;
  }

  .cot-cargando .lst01 {
    font-size: 25px;
  }

  .cot-cargando .lst01 li:not(:last-child) {
    margin-right: 18px;
  }

  .cot-cargando .lst01 li:not(:last-child)::after {
    padding-left: 26px;
  }

  .cot-cargando .track {
    margin: 120px 0 70px;
  }

  .cot-cargando .objs {
    width: 124px;
    height: 60px;
  }

  .cot-cargando .obj-1 {}

  .cot-cargando .copy {
    max-width: 795px;
    margin: 0 auto;
    font-size: 25px;
  }

  .cot-cargando .obj-1 {
    animation: anim-linear 10s linear infinite;
  }
}

@keyframes anim-linear {
  0% {
    left: -150px;
  }

  100% {
    left: 100%;
  }
}

/* */
.anim-dots {
  display: flex;
  flex-direction: row;
  padding-top: 3px;
  margin-left: 8px;
}

.anim-dots .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 3px;
  border-radius: 50%;
  background-color: #fff;
}

.anim-dots .dot:nth-last-child(1) {
  animation: jumpingAnimation 1.2s 0.6s linear infinite;
}

.anim-dots .dot:nth-last-child(2) {
  animation: jumpingAnimation 1.2s 0.3s linear infinite;
}

.anim-dots .dot:nth-last-child(3) {
  animation: jumpingAnimation 1.2s 0s linear infinite;
}

@keyframes jumpingAnimation {
  0% {
    transform: translate(0, 0);
  }

  16% {
    transform: translate(0, -10px);
  }

  33% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* breadcrumbs */
.cot-bc {
  padding: 0 0 15px 0;
}

.cot-bc .bc {
  padding: 10px 15px;
  background-color: var(--se-gray-dark);
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  text-align: left;
  line-height: 1.25;
}

.cot-bc .bc a {
  color: #fff;
}

.cot-bc .bc a span {
  font-weight: 700;
}

@media (min-width: 768px) {
  .cot-bc {
    padding: 26px 0 0;
    background-color: var(--se-gray-light);
  }

  .cot-bc .bc {
    font-size: 18px;
    padding: 12px 30px;
  }

  .cot-bc .bc a span {
    font-size: 18px;
  }
}

/* Encabezados previos al cuerpo */
/* Usado en inicio - pedido de patente */
.cot-heading01 {
  padding: 5px 0 20px;
  line-height: 1.25;
}

.cot-heading01 .title {
  font-size: 24px;
  font-weight: 700;
}

.cot-heading01 .subtitle {
  font-size: 16px;
}

/* Usado en resultado patente */
.cot-heading02 {
  padding: 8px 0 20px;
  line-height: 1.25;
}

.cot-heading02 .title {
  margin: -8px 0 10px;
  font-size: 24px;
  font-weight: 700;
}

.cot-heading02 .patente {
  position: relative;
  margin-bottom: -37px;
}

.cot-heading02 .patente::before {
  content: "";
  position: absolute;
  left: 0;
  top: 17px;
  width: 100%;
  height: 1px;
  background: #1e1e1e;
  z-index: -1;
}

.cot-heading02 .patente span {
  display: inline-block;
  padding: 4px 10px;
  background: #000;
  border-radius: 3px;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}

@media (min-width: 768px) {
  .cot-heading01 {
    padding: 20px 0;
  }

  .cot-heading02 {
    padding: 20px 0;
  }

  .cot-heading01 .subtitle {
    font-size: 24px;
  }
}

/* Cuerpo del cotizador */
.cot-body {
  padding: 25px 0 250px;
  height: 0;
}

.cot-body.cot-body-patente {
  padding: 25px 0 50px;
}

.cot-body.cot-body-l {
  min-height: 500px;
}

@media (min-width: 768px) {
  .cot-body {
    padding: 40px 0 230px;
  }

  .cot-body.cot-body-patente {
    padding: 20px 0;
  }
}

.cot-body .bi {
  width: 15px;
  height: 15px;
  fill: var(--bs-primary);
}

@media (min-width: 768px) {
  .cot-body .bi {
    width: 18px;
    height: 18px;
  }
}

/** Titulos de Rubro */
 
.category-title {
  float: none;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
}

.category-subtitle {
  float: none;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

/**/
.frm01 {}

.frm01 fieldset {}

.frm01 legend {
  float: none;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
}

.frm01 .legend-subtitle {
  float: none;
  margin-top: -5px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

.frm01 .form-help {
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
}

.frm01 .form-label {
  font-size: 14px;
}

.frm01 .form-buttons {
  margin-top: 30px;
}

.frm01 .form-alt {
  margin-top: 17px;
  font-size: 16px;
  font-weight: 500;
  color: var(--se-gray-aux);
}

.frm01 .form-alt a {
  color: var(--se-gray-aux);
}

.frm01 .form-alt a:hover {
  border-bottom: 1px solid var(--se-gray-aux);
}

.frm01 .form-alert {
  max-width: 770px;
  margin: 0 auto;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 205, 211, 0.5);
  border-radius: 5px;
}

.frm01 .form-alert .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.frm01 .form-alert .icon-m {
  width: 27px;
  height: 31px;
}

.frm01 .form-alert .falert-header {
  font-size: 20px;
  font-weight: 700;
}

.frm01 .form-alert .falert-body {
  padding: 18px 0;
  font-size: 17px;
  font-weight: 500;
}

.frm01 .form-alert .falert-footer {
  font-size: 18px;
  font-weight: 500;
}

.frm01 .form-alert .falert-footer a {
  color: var(--se-gray-aux);
}

.frm01 .form-alert .falert-footer a:hover span {
  border-bottom: 1px solid var(--se-gray-aux);
}

form>fieldset>p {
  color: red;
}

@media (min-width: 768px) {
  .frm01 legend {
    margin-bottom: 18px;
    font-size: 22px;
  }

  .frm01 .form-help {
    margin: -4px 0 18px;
    font-size: 15px;
  }

  .frm01 .form-label {
    font-size: 16px;
  }

  .frm01 .form-buttons {
    margin-top: 48px;
  }

  .frm01 .form-alert {
    padding: 25px 30px;
  }
}

/* botonera de marcas */
.brands-wrapper {
  margin: 20px 0 50px;
  gap: 25px;
}

.brands-wrapper a {
  display: block;
  width: 130px;
  padding: 0 0 10px;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 5px;
  color: #000;
}

.brands-wrapper a img {
  display: inline-block;
  width: 100%;
  height: auto;
}

.brands-wrapper a span {
  display: block;
  font-size: 14px;
  line-height: 1;
}

@media (min-width: 375px) {
  .brands-wrapper a {
    width: 152px;
  }
}

@media (min-width: 768px) {
  .brands-wrapper {
    max-width: 900px;
    margin: 20px auto 50px;
    gap: 30px;
  }
}

/* Modals */
.modal-se {
  font-size: 18px;
}

.modal-se .modal-body {
  padding-bottom: 40px;
}

.modal-se .title {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
}

.modal-se legend {
  font-weight: 500;
}

.modal-se .form-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-se .err-msg {
  font-size: 18px;
  color: var(--bs-form-invalid-color);
}

@media (min-width: 768px) {
  .modal-se .modal-body {
    padding-top: 0;
  }

  .modal-se legend {
    margin-bottom: 12px;
    font-size: 19px;
  }

  .modal-se .form-buttons {
    flex-direction: row;
  }
}

/* seccion patente - inicio */
/* .sec-patente {} */

.sec-patente.cot-body {
  padding: 20px 0;
}

@media (min-width: 768px) {
  /* .sec-patente.cot-body {} */

  .sec-patente .frm01 .form-buttons {
    margin-top: 20px;
  }

  .sec-patente .frm01 .form-alt {
    margin-top: 10px;
  }
}


@media (min-width: 1290px) {
  .columns-grid {
    width: 78%;
  }
}

@media (min-width: 1477px) {
  .columns-grid {
    width: 69%;
  }
}

@media (min-width: 1660px) {
  .columns-grid {
    width: 58%;
  }
}

@media (max-width: 1290px) {
  .columns-grid {
    width: 78%;
  }
}

@media (max-width: 1220px) {
  .columns-grid {
    width: 94%;
  }
}

@media (max-width: 1012px) {
  .columns-grid {
    width: 100%;
  }
}

@media (max-width: 989px) {
  .columns-grid {
    width: 85%;
  }
}

@media (max-width: 414px) {
  .columns-grid {
    width: 100%;
  }

  .gap-mobile {
    margin-left: .1rem;
    margin-right: .1rem;
    gap: 1rem !important;
  }
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.cookie-consent {
  display: flex;
  flex-direction: column;
  width: 90%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 20px;
  outline: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;
  margin-top: 28px;
  z-index: 9999;
}

.fade-in-top {
  animation: fadeInTop 0.5s ease forwards;
  opacity: 0;
  transform: translateY(-20px);
  /* Empieza 20px arriba */
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-out-top {
  animation: fadeOutTop 0.5s ease forwards;
}

@keyframes fadeOutTop {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

.fade-out {
  animation: fadeOut 0.5s;
}

@keyframes fadeOut {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.border-0 {
  border: 0 !important;
}

.modal-header {
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 0 16px;
  border-top-left-radius: calc(0.5rem - (1px));
  border-top-right-radius: calc(0.5rem - (1px));
}

.btn-modal {
  width: 100%;
}

.cookie-consent-text {
  font-size: 0.78em;
}

.allow-button {
  text-decoration: none !important;
  color: #3f84ff !important;
  font-weight: 600;
}

.policy-button {
  font-weight: 600;
}

.ff-mukta {
  font-family: 'Mukta', sans-serif;
}

.fs-9 {
  font-size: 0.570rem;
}

.fs-10 {
  font-size: 0.625rem;
}

.fs-12 {
  font-size: 0.75rem;
}

.fs-13 {
  font-size: 0.8125rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 1rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-32 {
  font-size: 32px;
}

.fs-34 {
  font-size: 34px;
}

.fs-40 {
  font-size: 40px;
}

.fs-42 {
  font-size: 42px;
}

.fs-44 {
  font-size: 44px;
}

.fs-46 {
  font-size: 46px;
}

.fs-50 {
  font-size: 50px;
}

@media (min-width: 360px) {
  .fs-ss-20 {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .lh-lg-1 {
    line-height: 1 !important;
  }

  .fs-lg-14 {
    font-size: 14px;
  }

  .fs-lg-16 {
    font-size: 16px;
  }

  .fs-lg-18 {
    font-size: 18px;
  }

  .fs-lg-20 {
    font-size: 20px;
  }

  .fs-lg-24 {
    font-size: 24px;
  }

  .fs-lg-30 {
    font-size: 30px;
  }

  .fs-lg-34 {
    font-size: 34px;
  }

  .fs-lg-40 {
    font-size: 40px;
  }

  .fs-lg-50 {
    font-size: 50px;
  }
}

.fw-bold {
  font-weight: 700 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.align-items-center {
  align-items: center !important;
}

.pb-lg-2 {
  padding-bottom: 0.5rem !important;
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-lg-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.checkbox-container {
  margin-bottom: 15px;
  margin-right: 5px;
}

.label-color {
  color: #2C7DFD;
}

.modal-terms {
  position: fixed;
  top: 5%;
  left: 5%;
}

.terms-tittle {
  text-align: center;
  margin-bottom: 15px;
}

.tel-align {
  padding-right: 3rem;
}

.certificado-button {
  min-inline-size: fit-content;
  line-height: normal;
  vertical-align: top;
}


@media (min-width: 768px) {
  .certificado-button {
    min-width: 0 !important;
  }
  
  .certificado-button .buttonIcon{
  margin-left: 5px;
  height: 0.8em !important;
  width: 0.8em !important;
  }

  .cambiar-certificado{
  flex-basis: fit-content;
  max-width: 25% !important;
  }
}

.li-body::marker {
  content: "- ";
}

.t-20 {
  position: relative;
  top: -20px;
}

.datos-archivo {
  display: flex;
}

.cambiar-certificado {
  /* padding-right: 3% */
}

.enviar-certificado {
  padding-right: 1%;
  /* text-align: right; */
}

@media (max-width: 768px) {
  .datos-archivo {
    flex-direction: column;
    align-items: center;
  }

  .cambiar-certificado {
    padding-right: 0%;
    /* padding-bottom: 3%; */
  }

  .enviar-certificado {
    padding-right: 0%;
    padding-bottom: 3%;
  }
}

.body-add-app {
  display: flex;
  max-width: 640px;
}

.subtitulo-add-app {
  align-content: end;
  margin-bottom: 1rem;
  position: relative;
  font-size: 20px;
  color: #fff;
}

.subtitulo-add-app-mobile {
  align-content: end;
  margin-bottom: 1rem;
  position: relative;
  font-size: 15px;
  color: #fff;
  padding-left: 32px;
}

.mt-57 {
  margin-top: 57px;
}

@media (max-width: 768px) {
  .mt-57 {
    margin-top: 0px;
  }
}

.hover-underline:hover {
  text-decoration: underline;
  cursor: pointer;
  /* Esto cambia el cursor al pasar sobre el texto */
}